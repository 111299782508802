import React, { useEffect, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Link, withRouter } from 'react-router-dom';
import { Row, Col, Card, CardTitle, CardBody, Alert } from "reactstrap";

import { Pagination, SearchInput } from "../../../../components/Custom";
import { TranslationTable } from '../TranslationTable';
import {
    fetchTranslations,
    translationsSetPage,
    translationsSetSearch,
} from '../../../../store/actions';

import * as HOC from '../../../../HOC';
const TableDataLoading = HOC.withTableDataLoading();

const TranslationListComponent = (props) => {
    useEffect(() => {
        let params = {
            page: props.page,
        };

        if (props.search) {
            params.filter = {
                keywords: props.search,
            };
        }

        props.fetchTranslations(params);
    }, [props.page, props.search]);

    const filterWarningVisible = useMemo(() => {
        return props.search && props.list.length === 0 && !props.listLoading;
    }, [props.list, props.listLoading, props.search]);

    const handlePageChange = (item) => {
        props.translationsSetPage(item.selected + 1);
    };

    const handleSearchChange = (value) => {
        props.translationsSetSearch(value);
    };

    return (
        <Row>
            <Col xl={12}>
                <Card>
                    <CardBody>
                        <Row className="no-gutters justify-content-between">
                            <CardTitle className={'mb-4'}>{props.t('title.translations')}</CardTitle>

                            <div className="button-items">
                                <Link to={`/translations/create`} className="btn btn-primary">
                                    {props.t('create')}
                                    <i className="fas fa-angle-right font-size-16 align-middle ml-2" />
                                </Link>
                            </div>
                        </Row>

                        <Row className={'no-gutters justify-content-between'}>
                            <SearchInput
                                initialValue={props.search}
                                onChange={handleSearchChange}
                                hint={false}
                            />
                        </Row>

                        <TableDataLoading isLoading={props.listLoading} isFailed={props.listError} error={props.listError}>
                            {filterWarningVisible ? (
                                <Alert color="primary">{props.t('filter.data.no_found')}</Alert>
                            ) : (
                                <>
                                    <TranslationTable
                                        items={props.list}
                                        isLoading={props.listLoading}
                                    />
                                    <Pagination data={props.meta} onPageChange={handlePageChange} />
                                </>
                            )}
                        </TableDataLoading>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

const mapStateToProps = state => {
    const { list, listLoading, listError, page, meta, search } = state.translation.list;
    return {
        list,
        listLoading,
        listError,
        page,
        meta,
        search,
    };
};

const mapDispatchToProps = {
    fetchTranslations,
    translationsSetPage,
    translationsSetSearch,
};

export const TranslationList = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TranslationListComponent)));