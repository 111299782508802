import React, {useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import {Card, CardBody, CardTitle, Row} from "reactstrap";

import {InvoicesTable, Pagination} from 'components/Custom';
import {
  userAccountInvoiceListGetData,
  userAccountInvoiceListCleanState,
  userAccountInvoiceListSetPage,
} from 'store/actions';

import * as HOC from 'HOC';

const TableDataLoading = HOC.withTableDataLoading();

const InvoicesContainerComponent = (props) => {
  useEffect(() => {
    return () => {
      props.userAccountInvoiceListCleanState();
    }
  }, []);

  useEffect(() => {
    let params = {
      page: props.page,
      // per_page: 10,
    };

    props.userAccountInvoiceListGetData(props.account?.id, params);
  }, [props.page]);

  const handlePageChange = (item) => {
    props.userAccountInvoiceListSetPage(item.selected + 1);
  };

  return (
    <Card>
      <CardBody>
        <Row className="no-gutters justify-content-between">
          <CardTitle className={'mb-4'}>{props.t('invoices')}</CardTitle>
        </Row>

        <TableDataLoading isLoading={props.listLoading} isFailed={props.listError} error={props.listError}>
          <>
            <InvoicesTable
              items={props.list}
              isLoading={props.listLoading}
              onSort={() => {
              }}
            />

            <Pagination data={props.meta} onPageChange={handlePageChange}/>
          </>
        </TableDataLoading>

      </CardBody>
    </Card>
  );
};

const mapStateToProps = state => {
  const {data: account} = state.userAccount.data;
  const {list, listLoading, listError, page, meta,} = state.userAccount.invoice.list;
  return {
    account,

    list,
    listLoading,
    listError,
    page,
    meta,
  };
};

const mapDispatchToProps = {
  userAccountInvoiceListGetData,
  userAccountInvoiceListCleanState,
  userAccountInvoiceListSetPage,
};

export const InvoicesContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InvoicesContainerComponent)));