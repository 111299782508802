import {takeLatest, fork, put, all, call} from 'redux-saga/effects';

import {USER_ACCOUNT_INVOICE_LIST_GET_DATA} from './actionTypes';
import {
  userAccountInvoiceListGetDataSuccess,
  userAccountInvoiceListGetDataError
} from './actions';
import {API} from 'api';
import {getMapRequestParams} from 'utils/request';

function* getData({payload: {id, params}}) {
  try {
    const requestParams = getMapRequestParams(params);

    const response = yield call(API.users.getUserInvoices, id, requestParams);

    const {data} = response;

    yield put(userAccountInvoiceListGetDataSuccess(data));

  } catch (error) {
    const message = error.response?.data?.message ? error.response.data.message : 'Error';
    yield put(userAccountInvoiceListGetDataError(message));
  }
}


export function* watchGetData() {
  yield takeLatest(USER_ACCOUNT_INVOICE_LIST_GET_DATA, getData)
}

function* listSaga() {
  yield all([
    fork(watchGetData),
  ]);
}

export default listSaga;