import React from 'react';
import { Table } from "reactstrap";
import { useTranslation } from 'react-i18next';

export const TableView = (props) => {
    const {
        // react-table props
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,

        // custom props
        getColumnStyle,
        columns,
        isLoading,
    } = props;

    const { t } = useTranslation();

    return (
        <Table className={'table-bordered table-part-bordered'} {...getTableProps()}>
            <thead>
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <th
                            {...column.getHeaderProps([
                                {
                                    className: column.headerClassName,
                                },
                                column.getSortByToggleProps({ title: null })
                            ])}
                            style={{ ...column.getHeaderProps(column.getSortByToggleProps().style), ...getColumnStyle(column) }}
                        >
                            <div className={'d-flex'}>
                                <div className={'d-flex flex-fill'}>
                                    {column.render('Header')}
                                </div>

                                <div>
                                    {column.canSort && (
                                        column.isSorted ? (
                                            <i className={'bx font-size-16 align-middle' + (column.isSortedDesc ? " bx-sort-down" : " bx-sort-up")} />
                                        ) : <i className="bx bx-sort align-middle" style={{ color: '#adb5bd' }} />
                                    )}
                                </div>
                            </div>
                        </th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {(
                rows.length > 0 && rows.map(row => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return (
                                    <td
                                        {...cell.getCellProps([
                                            {
                                                className: cell.column.className,
                                            },
                                        ])}
                                    >
                                        {cell.render('Cell')}
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })
            ) || (
                <tr>
                    <td colSpan={columns.length}>
                        {!isLoading ? t('empty') : <span dangerouslySetInnerHTML={{__html: '&nbsp;'}} />}
                    </td>
                </tr>
            )}
            </tbody>
        </Table>
    );
};