import {
  REMOVE_PROPERTY,
  REMOVE_PROPERTY_COMPLETED,
} from './actionTypes';


export const removeProperty = (id) => {
  return {type: REMOVE_PROPERTY, payload: { id }};
};


export const removePropertyCompleted = () => {
  return {type: REMOVE_PROPERTY_COMPLETED};
};

