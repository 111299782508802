import React, {useEffect, useMemo, useState} from 'react';
import {useSortBy, useTable} from "react-table";
import {PropertyStatusLabel, TableView} from "components/Custom";
import placeholder from 'assets/images/custom/property-placeholder.jpg';
import {useTranslation} from "react-i18next";
import NumberFormat from "react-number-format";
import SweetAlert from "react-bootstrap-sweetalert";
import {useDispatch} from "react-redux";
import {removeProperty} from "store/actions";


export const PropertiesListTable = props => {
      const {t} = useTranslation();
      const dispatch = useDispatch()
      const {items} = props;

      const [confirmModalData, setConfirmModalData] = useState(null);


      const handleRemoveClick = (id) => {
        setConfirmModalData(id)
      }
      const handleConfirm = () => {
        dispatch(removeProperty(confirmModalData))
        setConfirmModalData(null);
      };

      const data = useMemo(() => {
        return items;
      }, [items]);


      const [columns, setColumns] = useState([{
        Header: '',
        accessor: 'avatar',
        id: 'avatar',
        Cell: ({value}) => (
            <div style={{backgroundImage: `url("${value ? value : placeholder}")`}} className="property-cell-image"/>
        ),
        headerClassName: 'property-table-image-column-header',
        className: 'property-image-cell',
        disableSortBy: true,
      },  {
        Header: t('address'),
        accessor: 'address',
        id: 'address',
        Cell: ({value}) => {
          return (<div className="text-dark font-weight-bold">{value}</div>);
        },
      },{
        Header: t('city'),
        accessor: 'city.name',
        id: 'city',
      }, {
        Header: t('project'),
        accessor: 'project',
        id: 'project',
        Cell: ({row}) => (
            <div>{row.original.myReceivedLink ? '-' : row.original.project?.name}</div>
        ),
      },
        {
          Header: t('unit'),
          accessor: 'unit',
          id: 'unit',
          Cell: ({value}) => {
            return value ? value : '-';
          },
        }, {
          Header: () => (
              <div className={'w-100 text-center'}>{t('price')}</div>
          ),
          accessor: 'price',
          id: 'price',
          Cell: ({row: {original}, value}) => {
            return (
                <div className={'text-center text-dark font-weight-bold text-nowrap'}>
                  <div>
                    {value !== null && value >= 0 ? (
                        <NumberFormat
                            value={value}
                            thousandSeparator={' '}
                            prefix={'€ '}
                            displayType={'text'}
                        />
                    ) : '-'}
                  </div>
                </div>
            );
          }
        },
        {
          Header: t('status'),
          accessor: 'status',
          id: 'status',
          Cell: ({value}) => (
              <PropertyStatusLabel status={value}/>
          ),
        },{
          Header: "",
          id: "removeColumn",
          Cell: ({row: {original}}) => (
              <>
                <button className={"btn btn-danger btn-sm rounded-lg"} onClick={() => handleRemoveClick(original.id)}>
                  <i className={"bx bx-x font-size-16 align-middle"}/>
                </button>
              </>
          ),
        }]);


      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: {sortBy}
      } = useTable(
          {
            columns,
            data,
            initialState: {
              sortBy: props.initialSort || [],
            },
            defaultCanSort: false,
            disableSortBy: data.length === 0, // data.length === 0,
            manualSortBy: true,
            disableMultiSort: true,
            autoResetSortBy: false,
          },
          useSortBy,
      );

      useEffect(() => {
        if (props.onSort) {
          props.onSort(sortBy);
        }
      }, [props.onSort, sortBy]);

      const getColumnStyle = (column) => {
        return {...column.style};
      };

      return (
          <div className="table-responsive table-centered">
            <TableView
                getTableProps={getTableProps}
                getTableBodyProps={getTableBodyProps}
                headerGroups={headerGroups}
                rows={rows}
                prepareRow={prepareRow}
                getColumnStyle={getColumnStyle}
                columns={columns}
                isLoading={props.isLoading}
            />
            {confirmModalData && (
                <SweetAlert
                    title={t('confirm.sure')}
                    warning
                    showCancel
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="light"
                    onConfirm={() => {
                      handleConfirm();
                    }}
                    onCancel={() => {
                      setConfirmModalData(null);
                    }}
                    cancelBtnText={t('cancel')}
                >
                  {t('not.revert')}
                </SweetAlert>
            )}
          </div>
      );
    }
;

