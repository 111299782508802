import {
  USER_ACCOUNT_INVOICE_LIST_GET_DATA,
  USER_ACCOUNT_INVOICE_LIST_GET_DATA_SUCCESS,
  USER_ACCOUNT_INVOICE_LIST_GET_DATA_ERROR,
  USER_ACCOUNT_INVOICE_LIST_CLEAN_STATE,
  USER_ACCOUNT_INVOICE_LIST_SET_PAGE,
} from './actionTypes';

const initialState = {
  list: [],
  listLoading: false,
  listError: null,
  listIsFetched: false,
  meta: null,
  page: 1,
};

const list = (state = initialState, action) => {
  switch (action.type) {

    case USER_ACCOUNT_INVOICE_LIST_GET_DATA:
      state = {
        ...state,
        listLoading: true,
        listError: null,
      };
      break;

    case USER_ACCOUNT_INVOICE_LIST_GET_DATA_SUCCESS:
      state = {
        ...state,
        listLoading: false,
        listIsFetched: true,
        list: action.payload.data,
        meta: action.payload.meta,
      };
      break;

    case USER_ACCOUNT_INVOICE_LIST_GET_DATA_ERROR:
      state = {
        ...state,
        listLoading: false,
        listError: action.payload,
      };
      break;

    case USER_ACCOUNT_INVOICE_LIST_CLEAN_STATE:
      state = {
        ...state,
        ...initialState,
      };
      break;

    case USER_ACCOUNT_INVOICE_LIST_SET_PAGE:
      state = {
        ...state,
        page: action.payload,
      };
      break;

    default:
      state = {...state};
      break;
  }
  return state;
};

export default list;