import british from "../../assets/images/flags/br.jpg";
import french from "../../assets/images/flags/french.jpg";
import dutch from "../../assets/images/flags/dutch.jpg";

export const languages = [{
    code: 'en',
    title: 'English',
    flag: british,
}];

export const crmLanguages = [{
    code: 'nl',
    title: 'Nederlands',
    flag: dutch,
}, {
    code: 'en',
    title: 'English',
    flag: british,
}, {
    code: 'fr',
    title: 'Français',
    flag: french,
}];