import React, { useState } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import logoLightPng from "../../assets/images/logo-light.png";
import logoDark from "../../assets/images/logo-dark.png";

// Redux Store
import { showRightSidebarAction, toggleLeftmenu, changeSidebarType } from "../../store/actions";

const Header = (props) => {
    const isMobile =  /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    function tToggle() {
        props.toggleLeftmenu(!props.leftMenu);
        if (props.leftSideBarType === "default") {
            props.changeSidebarType("condensed", isMobile);
        } else if (props.leftSideBarType === "condensed") {
            props.changeSidebarType("default", isMobile);
        }
    }

    return (
        <React.Fragment>
            <header id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex">
                        <div className="navbar-brand-box">
                            <Link to={`/dashboard`} className="logo logo-dark">
                                <span className="logo-sm">
                                    <img src={logoLightPng} alt="" height="22" />
                                </span>
                                <span className="logo-lg">
                                    <img src={logoDark} alt="" height="26" />
                                </span>
                            </Link>

                            <Link to={`/dashboard`} className="logo logo-light">
                                <span className="logo-sm">
                                    <img src={logoLightPng} alt="" height="22" />
                                </span>
                                <span className="logo-lg">
                                    <img src={logoLightPng} alt="" height="26" />
                                </span>
                                <div className={'logo-brand logo-lg'}> Brikk</div>
                            </Link>
                        </div>

                        <button type="button" onClick={() => {   tToggle() }} className="btn btn-sm px-3 font-size-16 header-item waves-effect" id="vertical-menu-btn">
                            <i className="fa fa-fw fa-bars"></i>
                        </button>

                    </div>

                    <div className="d-flex">

                        {/*<NotificationDropdown />*/}
                        <ProfileMenu />

                    </div>
                </div>
            </header>
        </React.Fragment>
    );
}
const mapStatetoProps = state => {
    const { layoutType,showRightSidebar,leftMenu,leftSideBarType } = state.Layout;
    return {
        layoutType,showRightSidebar,leftMenu,leftSideBarType,
        user: state.user.data,
    };
};

export default connect(mapStatetoProps, { showRightSidebarAction,toggleLeftmenu,changeSidebarType })(withTranslation()(Header));
