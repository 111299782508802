import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';
import toastr from 'toastr';
import i18n from '../../../i18n';

import { EDIT_TRANSLATION } from './actionTypes';
import {
    editTranslationSuccess,
    editTranslationError,
} from './actions';

import { FORM_TRANSLATION } from '../../../consts/forms';

import { API } from '../../../api';

function* editTranslation({ payload: { id, params, history } }) {
    try {
        const response = yield call(API.translation.updateTranslation, id, params);

        const { data } = response;

        yield put(editTranslationSuccess(true));
        history.push('/translations');

        toastr.success(i18n.t('translation.edit.success'));
    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(editTranslationError({
            errors,
            message
        }));

        yield put(stopSubmit(FORM_TRANSLATION, errors));
    }
}

export function* watchEditTranslation() {
    yield takeEvery(EDIT_TRANSLATION, editTranslation)
}

function* editSaga() {
    yield all([
        fork(watchEditTranslation),
    ]);
}

export default editSaga;