import React from 'react';
import { connect } from "react-redux";
import { Switch, withRouter } from "react-router-dom";

import {
    publicRoutes,
} from "../../routes/allRoutes";
import { AuthLayout, BlankLayout } from "../../components/Custom";
import { PublicRoute }  from "../../routes/middleware/PublicRoute";

const AuthIndexComponent = (props) => {
    return (
        <AuthLayout>
            <Switch>
                {publicRoutes.map((route, idx) => (
                    <PublicRoute
                        path={route.path}
                        layout={BlankLayout}
                        component={route.component}
                        key={idx}
                        isLoggedIn={props.isLoggedIn}
                    />
                ))}
            </Switch>
        </AuthLayout>
    );
};

const mapStateToProps = state => {
    return {
        isLoggedIn: state.Login.isLoggedIn,
    };
};

const mapDispatchToProps = {
};

export const AuthIndex = withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthIndexComponent));