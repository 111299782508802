import {
    CREATE_TRANSLATION,
    CREATE_TRANSLATION_SUCCESS,
    CREATE_TRANSLATION_ERROR,
    CLEAN_CREATE_TRANSLATION,
} from './actionTypes';

export const createTranslation = (params, history) => {
    return {
        type: CREATE_TRANSLATION,
        payload: { params, history },
    }
};

export const createTranslationSuccess = (data) => {
    return {
        type: CREATE_TRANSLATION_SUCCESS,
        payload: data,
    }
};

export const createTranslationError = (data) => {
    return {
        type: CREATE_TRANSLATION_ERROR,
        payload: data,
    }
};

export const cleanCreateTranslation = () => {
    return { type: CLEAN_CREATE_TRANSLATION };
};