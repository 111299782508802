import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {isEqual} from "lodash"
import {useParams, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import {PageTitle} from "../../components/Custom";
import {
  cleanPropertyListPage,
  fetchPropertyList,
  propertyResetSort,
  propertySetPage,
  propertySetSort
} from "../../store/property/list/actions";
import {PropertiesListTableView} from "./components";

export const PropertiesComponent = props => {
  const {id} = useParams();

  const [search, setSearch] = useState(null);

  useEffect(() => {
    let params = {
      page: props.page,
      per_page: 12
    };

    if (props.filterIsActive) {
      params.filter = props.filter;
    }


    if (props.sort.length > 0) {
      params.sort = props.sort;
    }

    if (search) {
      params.filter = {
        ...params.filter,
        keywords: search,
        search_fields: ['address', 'unit'],
      };
    }

    props.fetchPropertyList(id, params);

  }, [props.filterIsActive, props.filter, props.sort, props.page, search]);

  useEffect(() => {
    return () => {
      props.cleanPropertyListPage();
      props.propertyResetSort();
    }
  }, []);

  const filterWarningVisible = useMemo(() => {
    return (props.filterIsActive || props.search) && props.list.length === 0 && !props.listLoading;
  }, [props.filterIsActive, props.list, props.listLoading, props.search]);

  const handleSort = useCallback(sortBy => {
    if (!isEqual(sortBy, props.sort)) {
      props.propertySetSort(sortBy);
    }
  }, [props.sort]);

  const handlePageChange = (item) => {
    props.propertySetPage(item.selected + 1);
  };

  const handleSearchChange = (value) => {
    props.propertySetPage(1);
    setSearch(value);
  };


  return (
    <>
      <PageTitle title={props.t("title.property.list")}/>
      <PropertiesListTableView
        title={props.t("title.property.list")}
        search={search}
        onSearch={handleSearchChange}
        list={props.list}
        listError={props.listError}
        listLoading={props.listLoading}
        filterWarningVisible={filterWarningVisible}
        meta={props.meta}
        onSort={handleSort}
        onPageChange={handlePageChange}
        userId={id}
      />
    </>
  );
};

const mapStateToProps = state => {
  const {filter, filterIsActive, sort, page, list, listLoading, listError, meta} = state.property.list;
  return {
    filter,
    filterIsActive,
    sort,
    page,
    list,
    listLoading,
    listError,
    meta
  };
};

const mapDispatchToProps = {
  fetchPropertyList,
  cleanPropertyListPage,
  propertyResetSort,
  propertySetPage,
  propertySetSort
};

export const Properties = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PropertiesComponent)));


