import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { stopSubmit, reset } from 'redux-form';
import toastr from 'toastr';
import i18n from '../../../i18n';

import { CREATE_TRANSLATION } from './actionTypes';
import {
    createTranslationSuccess,
    createTranslationError,
} from './actions';

import { FORM_TRANSLATION } from '../../../consts/forms';

import { API } from '../../../api';

function* createTranslation({ payload: { params, history } }) {
    try {
        const response = yield call(API.translation.createTranslation, params);

        const { data } = response;

        yield put(createTranslationSuccess(true));
        yield put(reset(FORM_TRANSLATION));

        toastr.success(i18n.t('translation.create.success'));
    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(createTranslationError({
            errors,
            message
        }));

        yield put(stopSubmit(FORM_TRANSLATION, errors));
    }
}

export function* watchCreateTranslation() {
    yield takeEvery(CREATE_TRANSLATION, createTranslation)
}

function* createSaga() {
    yield all([
        fork(watchCreateTranslation),
    ]);
}

export default createSaga;