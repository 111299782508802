import React from 'react';
import PropTypes from 'prop-types';
import {Alert, Card, CardBody, CardTitle, Col, Row} from "reactstrap";
import {Pagination, SearchInput} from "components/Custom";
import * as HOC from 'HOC';
import {useTranslation} from "react-i18next";
import {ImportProperties} from "../ImportProperties";
import {PropertiesListTable} from "./PropertiesListTable";


const TableDataLoading = HOC.withTableDataLoading();

export const PropertiesListTableView = props => {
  const {t} = useTranslation();

  return (
      <Row>
        <Col xl={12}>
          <Card>
            <CardBody>
              <Row className="no-gutters justify-content-between">
                <CardTitle className={'mb-4'}>{props.title}</CardTitle>

                <div className="button-items">
                    <ImportProperties userId={props.userId} />
                </div>
              </Row>

              <Row className={'no-gutters justify-content-between'}>
                <SearchInput
                    initialValue={props.search}
                    onChange={props.onSearch}
                    hint={false}
                />
              </Row>

              <TableDataLoading isLoading={props.listLoading} isFailed={props.listError} error={props.listError}>
                {props.filterWarningVisible ? (
                    <Alert color="primary">{t('filter.data.no_found')}</Alert>
                ) : (
                    <>
                      <PropertiesListTable
                          items={props.list}
                          onSort={props.onSort}
                          isLoading={props.listLoading}
                      />
                      <Pagination data={props.meta} onPageChange={props.onPageChange}/>
                    </>
                )}
              </TableDataLoading>
            </CardBody>
          </Card>
        </Col>
      </Row>
  );
};

PropertiesListTableView.propTypes = {
  title: PropTypes.string,
  filterWarningVisible: PropTypes.bool,
  onPageChange: PropTypes.func,
  list: PropTypes.array,
  onSort: PropTypes.func,
  listLoading: PropTypes.bool,
  meta: PropTypes.object,
  listError: PropTypes.object,
  search: PropTypes.string,
  onSearch: PropTypes.func,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};







