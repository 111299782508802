import React, { useEffect } from 'react';

import { Switch, BrowserRouter as Router, Route, useLocation, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';

import 'toastr/build/toastr.min.css';
import 'leaflet/dist/leaflet.css';

// Import Routes all
import { publicRoutes, utilityRoutes, } from "./routes/allRoutes";

// layouts Format
import { BlankLayout } from "./components/Custom";
import { CommonRoute }  from "./routes/middleware/CommonRoute";
import PrivateRoute from "./routes/middleware/PrivateRoute";

import { AuthIndex, Main } from './pages';

import { setLanguage } from "./store/actions";
import { API } from './api';

// Import scss
import "./assets/scss/theme.scss";

export const AppRouteSwitch = (props) => {
    let location = useLocation();

    let path = props.isLoggedIn ? '/dashboard' : '/login';

    return (
        <Redirect to={{ ...location, pathname: path}} />
    )
};

const App = (props) => {
    const { i18n } = useTranslation();

    useEffect(() => {
        if (!props.isLoggedIn) {
            props.setLanguage(i18n.language);
            API.setLang(i18n.language);
        }
    }, []);

    return (
        <React.Fragment>
            <Router>
                <Switch>
                    <Route exact path={'/'}>
                        <AppRouteSwitch isLoggedIn={props.isLoggedIn} />
                    </Route>

                    <Route {...props}
                           path={[
                               ...publicRoutes.map(item => item.path),
                           ]}
                    >
                        <AuthIndex {...props} />
                    </Route>

                    {utilityRoutes.map((route, idx) => (
                        <CommonRoute path={route.path} layout={BlankLayout} component={route.component} key={idx}/>
                    ))}

                    <PrivateRoute
                        path={'/'}
                        component={Main}
                    />
                </Switch>
            </Router>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    return {
        isLoggedIn: state.Login.isLoggedIn,
    };
};

const mapDispatchToProps = {
    setLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
