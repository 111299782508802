import {
    FETCH_TRANSLATIONS,
    FETCH_TRANSLATIONS_SUCCESS,
    FETCH_TRANSLATIONS_ERROR,
    CLEAN_TRANSLATIONS,

    TRANSLATIONS_SET_PAGE,
    TRANSLATIONS_SET_SEARCH,
} from './actionTypes';

export const fetchTranslations = (params) => {
    return {
        type: FETCH_TRANSLATIONS,
        payload: { params }
    }
};

export const fetchTranslationsSuccess = (data) => {
    return {
        type: FETCH_TRANSLATIONS_SUCCESS,
        payload: data,
    }
};

export const fetchTranslationsError = (data) => {
    return {
        type: FETCH_TRANSLATIONS_ERROR,
        payload: data,
    }
};

export const cleanTranslations = () => {
    return { type: CLEAN_TRANSLATIONS };
};

// SET PAGE
export const translationsSetPage = (data) => {
    return {
        type: TRANSLATIONS_SET_PAGE,
        payload: data,
    }
};

// SET SEARCH
export const translationsSetSearch = (data) => {
    return {
        type: TRANSLATIONS_SET_SEARCH,
        payload: data,
    }
};