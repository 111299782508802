import {takeEvery, fork, put, all, call} from "redux-saga/effects"
import toastr from "toastr"
import i18n from "../../../i18n"
import {API} from "api"
import {removePropertyCompleted} from "./actions"
import {REMOVE_PROPERTY} from "./actionTypes";
import {triggerFetchPropertyList} from "../list/actions";

function* remove({payload: {id}}) {
  try {

    const response = yield call(API.property.deleteProperty, id)

    yield put(removePropertyCompleted())
    yield put(triggerFetchPropertyList());
    toastr.success(i18n.t('property.delete.success'))

  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")
    yield put(
        removePropertyCompleted()
    )
    toastr.error(message)
  }
}

export function* watchRemove() {
  yield takeEvery(REMOVE_PROPERTY, remove)
}

function* removeSaga() {
  yield all([fork(watchRemove)])
}

export default removeSaga