import axios from 'axios';

class UserAPI {
    getMe = async (params) => {
        return axios.get('api/profile', { params });
    };

    editProfile = async (params) => {
        return axios.put('api/profile', params);
    };

    removeUser = async (params) => {
        return axios.delete('api/profile', params);
    };

    changePassword = async (params) => {
        return axios.post('api/profile/password', params);
    };

    updateAvatar = async (data) => {
        let formData = new FormData();
        formData.append('avatar', data);

        return axios.post('api/profile/avatar', formData);
    };

    removeAvatar = async () => {
        return axios.delete('api/profile/avatar');
    };

    getUser = async (id, params) => {
        return axios.get(`api/users/${id}`, { params });
    };
}

export const user = new UserAPI();
