import React, { useState, useEffect, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { useTable, useSortBy } from 'react-table';
import { withRouter, Link } from 'react-router-dom';

import { TableView } from '../../../../components/Custom';

const TranslationTableComponent = (props) => {
    const { items } = props;

    const data = useMemo(() => {
        return items;
    }, [items]);

    const [columns, setColumns] = useState([{
        Header: props.t('key'),
        accessor: 'key',
        id: 'key',
        Cell: ({ row: { original }, value }) => {
            return <Link to={`/translations/${original.id}/edit`} className="text-decoration-underline text-dark">{value}</Link>;
        },
    }, {
        Header: 'Nederlands',
        accessor: 'value.nl',
        id: 'nl',
        Cell: ({ row: { original }, value }) => {
            return <Link to={`/translations/${original.id}/edit`} className="text-dark">{value}</Link>;
        },
        className: 'cell-truncate',
    }, {
        Header: 'English',
        accessor: 'value.en',
        id: 'en',
        Cell: ({ row: { original }, value }) => {
            return <Link to={`/translations/${original.id}/edit`} className="text-dark">{value}</Link>;
        },
        className: 'cell-truncate',
    }, {
        Header: 'Français',
        accessor: 'value.fr',
        id: 'fr',
        Cell: ({ row: { original }, value }) => {
            return <Link to={`/translations/${original.id}/edit`} className="text-dark">{value}</Link>;
        },
        className: 'cell-truncate',
    }]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { sortBy }
    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: props.initialSort || [],
            },
            defaultCanSort: false,
            disableSortBy: true, // data.length === 0,
            manualSortBy: true,
            disableMultiSort: true,
            autoResetSortBy: false,
        },
        useSortBy,
    );

    useEffect(() => {
        if (props.onSort){
            props.onSort(sortBy);
        }
    }, [props.onSort, sortBy]);

    const getColumnStyle = (column) => {
        return { ...column.style};
    };

    return (
        <div className="table-responsive table-centered">
            <TableView
                getTableProps={getTableProps}
                getTableBodyProps={getTableBodyProps}
                headerGroups={headerGroups}
                rows={rows}
                prepareRow={prepareRow}
                getColumnStyle={getColumnStyle}
                columns={columns}
                isLoading={props.isLoading}
            />
        </div>
    );
};

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = {
};

export const TranslationTable = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TranslationTableComponent)));