import { takeLatest, fork, put, all, call } from 'redux-saga/effects';

import { FETCH_USERS } from './actionTypes';
import {
    fetchUsersSuccess,
    fetchUsersError,
} from './actions';

import { API } from '../../../api';
import { getMapRequestParams } from '../../../utils/request';

function* fetchUsers({ payload: { params } }) {
    try {
        const requestParams = getMapRequestParams(params);

        const response = yield call(API.users.getUsers, requestParams);

        const { data } = response;
        yield put(fetchUsersSuccess(data));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchUsersError(message));
    }
}

export function* watchUsersFetch() {
    yield takeLatest(FETCH_USERS, fetchUsers)
}

function* listSaga() {
    yield all([
        fork(watchUsersFetch),
    ]);
}

export default listSaga;