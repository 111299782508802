import {
  FETCH_USERS,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_ERROR,
  CLEAN_USERS,

  USERS_SET_PAGE,
  USERS_SET_SEARCH,
  USERS_SET_FILTER,
  USERS_RESET_SORT,
  USERS_SET_SORT,
  USERS_RESET_FILTER,
} from './actionTypes';

const initialState = {
  list: [],
  listLoading: false,
  listError: null,
  page: 1,
  meta: null,

  search: null,
  filter: {
    companies: null,
  },
  filterIsActive: false,
  sort: [],
};

const list = (state = initialState, action) => {
  switch (action.type) {

    case FETCH_USERS:
      state = {
        ...state,
        listLoading: true,
        listError: null,
      };
      break;

    case FETCH_USERS_SUCCESS:
      state = {
        ...state,
        listLoading: false,
        list: action.payload.data,
        meta: action.payload.meta,
      };
      break;

    case FETCH_USERS_ERROR:
      state = {
        ...state,
        listLoading: false,
        listError: action.payload,
      };
      break;

    case CLEAN_USERS:
      state = {
        ...state,
        ...initialState,
      };
      break;

    case USERS_SET_PAGE:
      state = {
        ...state,
        page: action.payload,
      };
      break;

    case USERS_SET_SEARCH:
      state = {
        ...state,
        search: action.payload,
        page: initialState.page,
      };
      break;

    case USERS_SET_FILTER:
      state = {
        ...state,
        filter: {
          ...initialState.filter,
          ...action.payload,
        },
        filterIsActive: true,
        page: initialState.page,
      };
      break;

    case USERS_RESET_FILTER:
      state = {
        ...state,
        filter: initialState.filter,
        filterIsActive: false,
        page: initialState.page,
      };
      break;


      // PROPERTY SORT
    case USERS_SET_SORT:
      state = {
        ...state,
        sort: action.payload,
        page: initialState.page,
      };
      break;

    case USERS_RESET_SORT:
      state = {
        ...state,
        sort: initialState.sort,
        page: initialState.page,
      };
      break;

    default:
      state = {...state};
      break;
  }
  return state;
};

export default list;