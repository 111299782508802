import {
    FETCH_TRANSLATION,
    FETCH_TRANSLATION_SUCCESS,
    FETCH_TRANSLATION_ERROR,
    CLEAN_FETCH_TRANSLATION,
} from './actionTypes';

const initialState = {
    data: null,
    dataLoading: false,
    dataError: null,
};

const view = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TRANSLATION:
            state = {
                ...state,
                dataLoading: true,
                dataError: null,
            };
            break;

        case FETCH_TRANSLATION_SUCCESS:
            state = {
                ...state,
                dataLoading: false,
                data: action.payload,
            };
            break;

        case FETCH_TRANSLATION_ERROR:
            state = {
                ...state,
                dataLoading: false,
                dataError: action.payload,
            };
            break;

        case CLEAN_FETCH_TRANSLATION:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default view;