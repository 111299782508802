import React from "react"
import {withRouter} from "react-router-dom"
import {Card, CardBody, Col, Row, Table} from "reactstrap"
import {connect} from "react-redux"
import {withTranslation} from 'react-i18next';
import moment from "moment";
import NumberFormat from 'react-number-format';

import './InvoiceDetailsCard.scss';
import {hiddenNumber} from 'utils/card/format';
import {phone} from 'utils/format';
import {LogoIcon} from 'components/Custom';

const InvoiceDetailsCardComponent = props => {
  const {invoice} = props

  const printInvoice = () => {
    window.print()
  }

  const renderBillingInfo = (value) => {
    if (value) {
      return (
        <>
          <span>{value}</span>
          <br/>
        </>
      )
    }
    return null;
  };

  return (
    <Row>
      <Col lg="12">
        <Card>
          <CardBody>
            <div className="invoice-title">
              <h4 className="float-right font-size-16">
                {props.t('invoice')} {invoice.number}
              </h4>
              <div className="mb-4">
                <LogoIcon size={"sm"}/>
              </div>
            </div>
            <hr/>
            <Row>
              <Col xs="6">
                <address>
                  <strong>{props.t('billed_to')}:</strong>
                  <br/>
                  {renderBillingInfo(invoice.customer_name)}
                  {renderBillingInfo(invoice.customer_company)}
                  {renderBillingInfo(phone(invoice.customer_phone))}
                  {renderBillingInfo(invoice.customer_address_city)}
                  {renderBillingInfo(invoice.customer_address_line)}
                  {renderBillingInfo(invoice.customer_address_zip)}
                </address>
              </Col>
            </Row>
            <Row>
              <Col xs="6" className="mt-3">
                <address>
                  <strong>{props.t('payment_method')}:</strong>
                  <br/>
                  {invoice.card_brand && (
                    <span className={'text-capitalize'}>{invoice.card_brand}&nbsp;</span>
                  )}
                  {invoice.card_last4 ? hiddenNumber(invoice.card_last4) : '-'}
                  <br/>
                  {invoice.customer_email}
                </address>
              </Col>
              <Col xs="6" className="mt-3 text-right">
                <address>
                  <strong>{props.t('date')}:</strong>
                  <br/>
                  {moment(invoice.created_at).format('DD/MM/YYYY')}
                  <br/>
                  <br/>
                </address>
              </Col>
            </Row>
            <div className="py-2 mt-3">
              <h3 className="font-size-15 font-weight-bold">
                {props.t('summary')}
              </h3>
            </div>
            <div className="table-responsive">
              <Table className="table-nowrap">
                <thead>
                <tr>
                  <th style={{width: "70px"}}>{props.t('number.no_abbreviation')}</th>
                  <th>{props.t('description')}</th>
                  <th>{props.t('quantity')}</th>
                  <th className="text-right">{props.t('price')}</th>
                </tr>
                </thead>
                <tbody>
                {props.invoice.lines && !!props.invoice.lines.length && (
                  props.invoice.lines.map((item, index) => (
                    <tr key={'details-' + index}>
                      <td>{index + 1}</td>
                      <td>{item.description}</td>
                      <td>{item.quantity}</td>
                      <td className="text-right">
                        <NumberFormat
                          value={item.amount}
                          thousandSeparator={' '}
                          prefix={' € '}
                          displayType={'text'}
                        />
                      </td>
                    </tr>
                  ))
                )}

                <tr>
                  <td colSpan="3" className="text-right">
                    {props.t('subtotal')}
                  </td>
                  <td className="text-right">
                    <NumberFormat
                      value={props.invoice.subtotal}
                      thousandSeparator={' '}
                      prefix={' € '}
                      displayType={'text'}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan="3" className="border-0 text-right">
                    <strong>{props.t('total')}</strong>
                  </td>
                  <td className="border-0 text-right">
                    <h4 className="m-0">
                      <NumberFormat
                        value={props.invoice.total}
                        thousandSeparator={' '}
                        prefix={' € '}
                        displayType={'text'}
                      />
                    </h4>
                  </td>
                </tr>
                </tbody>
              </Table>
            </div>
            {/*<div className="d-print-none">*/}
            {/*    <div className="float-right">*/}
            {/*        <Link*/}
            {/*            to="#"*/}
            {/*            onClick={printInvoice}*/}
            {/*            className="btn btn-success waves-effect waves-light mr-2"*/}
            {/*        >*/}
            {/*            <i className="fa fa-print" />*/}
            {/*        </Link>*/}
            {/*    </div>*/}
            {/*</div>*/}
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = {};

export const InvoiceDetailsCard = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InvoiceDetailsCardComponent)));