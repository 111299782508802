import React, { useEffect } from 'react';
import { withRouter, Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { connect } from "react-redux";

import { Translations, TranslationCreate, TranslationEdit } from './'
import AllowedRoute from '../../routes/middleware/AllowedRoute';
import { cleanTranslations } from '../../store/actions';

const TranslationIndexComponent = (props) => {
    let { path } = useRouteMatch();

    useEffect(() => {
        return () => {
            props.cleanTranslations();
        };
    }, [])

    return (
        <Switch>
            <AllowedRoute exact path={path} component={Translations} allowed={true} />
            <AllowedRoute path={`${path}/create`} component={TranslationCreate} allowed={true} />
            <AllowedRoute path={`${path}/:id/edit`} component={TranslationEdit} allowed={true} />

            <Route>
                <Redirect to={path} />
            </Route>
        </Switch>
    );
};

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = {
    cleanTranslations,
}

export const TranslationIndex = withRouter(connect(mapStateToProps, mapDispatchToProps)(TranslationIndexComponent));