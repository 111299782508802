import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { EchoClient } from 'services';
import {echoAddChannelListener, onUpdatePropertyImportEvent} from "store/actions";
import {UPDATE_PROPERTY_IMPORT_EVENT} from "consts/echo";


const SocketWrapperComponent = (props) => {

    useEffect(() => {
        EchoClient.create();
        props.echoAddChannelListener(`user.${props.user.id}`, UPDATE_PROPERTY_IMPORT_EVENT, props.onUpdatePropertyImportEvent);
        return () => {
            EchoClient.leaveAll();
            EchoClient.destroy();
        };
    }, []);

    return (
        props.children
    );
};


const mapStateToProps = state => {
    const { data } = state.user;
    return {
        user: data,
    };
};

const mapDispatchToProps = {
    echoAddChannelListener,
    onUpdatePropertyImportEvent
};

export const SocketWrapper = connect(mapStateToProps, mapDispatchToProps)(SocketWrapperComponent);