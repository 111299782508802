import {all} from 'redux-saga/effects';

import listSaga from './list/saga';

function* invoiceSaga() {
  yield all([
    listSaga(),
  ])
}

export default invoiceSaga;