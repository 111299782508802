import React from 'react';
import {
  withRouter,
} from "react-router-dom"
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Container} from "reactstrap";

import {Routing} from "./components";

const BillingComponent = (props) => {
  return (
    <div className="page-content min-vh-100">
      <Container fluid>
        <Routing/>
      </Container>
    </div>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = {};

export const Billing = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BillingComponent)));

