import {
    CREATE_TRANSLATION,
    CREATE_TRANSLATION_SUCCESS,
    CREATE_TRANSLATION_ERROR,
    CLEAN_CREATE_TRANSLATION,
} from './actionTypes';

const initialState = {
    createLoading: false,
    createSuccess: null,
    createError: null,
};

const create = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_TRANSLATION:
            state = {
                ...state,
                createLoading: true,
                createSuccess: null,
                createError: null,
            };
            break;

        case CREATE_TRANSLATION_SUCCESS:
            state = {
                ...state,
                createLoading: false,
                createSuccess: action.payload,
            };
            break;

        case CREATE_TRANSLATION_ERROR:
            state = {
                ...state,
                createLoading: false,
                createError: action.payload,
            };
            break;

        case CLEAN_CREATE_TRANSLATION:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default create;