import React from "react";
import { Route, Redirect } from "react-router-dom";

export const PublicRoute = ({
    component: Component,
    layout: Layout,
    isLoggedIn,
    ...rest
}) => (
    <Route
        render={props => {
            if (isLoggedIn) {
                return (
                    <Redirect to={{ pathname: "/dashboard", state: { from: props.location } }} />
                );
            }
            return (
                <Layout>
                    <Component {...props} />
                </Layout>
            );
        }}
        {...rest}
    />
);