import React from 'react';
import { Nav, NavItem, NavLink } from "reactstrap";
import { useTranslation } from 'react-i18next';
import classnames from "classnames"

export const LanguageTabsNav = (props) => {
    const { t } = useTranslation();

    const { active, tabs, onChange } = props;

    const onClick = (tab) => {
        if (active.code !== tab.code) {
            onChange(tab);
        }
    };

    return (
        <>
            <Nav pills>
                {tabs.map(item => (
                    <NavItem key={item.code}>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: active.code === item.code,
                            })}
                            onClick={() => onClick(item)}
                        >
                            {item.title}
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>
        </>
    );
};