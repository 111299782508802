import {combineReducers} from "redux";

import data from './data/reducer';
import invoice from './invoice/reducers';

const userAccountReducer = combineReducers({
  data,
  invoice,
});

export default userAccountReducer;