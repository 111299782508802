import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { ON_UPDATE_PROPERTY_IMPORT_EVENT } from './actionTypes';
import i18n from "../../../i18n";
import { importPropertyCompleted } from "../../property/import/actions";
import toastr from "toastr";
import { triggerFetchPropertyList } from "../../property/list/actions";

function* onUpdatePropertyImportEvent({ payload: { data } }) {
    //Check if successfully imported
    if (data["property-importer"].last_status) {
        toastr.success(i18n.t('property.import.success'));
        yield put(importPropertyCompleted());
        yield put(triggerFetchPropertyList());
    } else {
        toastr.error(data["property-importer"].text);
        yield put(importPropertyCompleted());
    }
}

export function* watchUpdatePropertyImportEvent() {
    yield takeEvery(ON_UPDATE_PROPERTY_IMPORT_EVENT, onUpdatePropertyImportEvent)
}

function* importSaga() {
    yield all([
        fork(watchUpdatePropertyImportEvent),
    ]);
}

export default importSaga;
