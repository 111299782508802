import React from 'react';
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  generatePath,
} from "react-router-dom"

import AllowedRoute from 'routes/middleware/AllowedRoute';
import {USER_ROLE_MANAGER} from "consts/roles";
import {General} from "../../General";
import {Properties} from "../../Properties";
import {Invoices} from "../../Invoices";

export const Routing = () => {
  let {path} = useRouteMatch()
  const {id} = useParams()

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={generatePath(`${path}/general`, {id: id})}/>
      </Route>
      <AllowedRoute path={`${path}/general`} component={General} allowed={true}/>
      <AllowedRoute path={`${path}/properties`} component={Properties} allowed={true}/>
      <AllowedRoute path={`${path}/invoices`} component={Invoices} allowed={USER_ROLE_MANAGER}/>

      <Route>
        <Redirect to={generatePath(`${path}/general`, {id: id})}/>
      </Route>
    </Switch>
  );
};

