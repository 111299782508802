import {
    FETCH_TRANSLATION,
    FETCH_TRANSLATION_SUCCESS,
    FETCH_TRANSLATION_ERROR,
    CLEAN_FETCH_TRANSLATION,
} from './actionTypes';

export const fetchTranslation = (id) => {
    return {
        type: FETCH_TRANSLATION,
        payload: { id },
    }
};

export const fetchTranslationSuccess = (data) => {
    return {
        type: FETCH_TRANSLATION_SUCCESS,
        payload: data,
    }
};

export const fetchTranslationError = (data) => {
    return {
        type: FETCH_TRANSLATION_ERROR,
        payload: data,
    }
};

export const cleanTranslation = () => {
    return { type: CLEAN_FETCH_TRANSLATION };
};