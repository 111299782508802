import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {API} from "../../../api";
import {IMPORT_PROPERTY} from "./actionTypes";
import {importPropertyFailed} from "./actions";

function* importProperty({ payload: {userId, params, onSuccess, reject } }) {
    try {
        if (onSuccess) {
            //Do not wait for response and close the modal. Response come from socket
            onSuccess()
        }

        const response = yield call(API.property.importProperty, userId, params);


    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(importPropertyFailed())
        reject(message)
    }
}

export function* watchImportProperty() {
    yield takeEvery(IMPORT_PROPERTY, importProperty)
}

function* importSaga() {
    yield all([
        fork(watchImportProperty),
    ]);
}

export default importSaga;