import axios from 'axios';
import {paramsSerializer} from "../../utils/request";

class PropertyAPI {
  getUserPropertyList = async (id, params) => {
    return axios.get(`api/admin/users/${id}/properties`, {
      params,
      paramsSerializer: () => paramsSerializer(params),
    });
  }

  importProperty = async (userId, data) => {
    let formData = new FormData();
    formData.append('file', data.file);
    formData.append('user_id', userId);
    return axios.post('api/admin/property-imports', formData)
  }

  deleteProperty = async (id) => {
    return axios.delete(`api/properties/${id}`);
  };
}

export const property = new PropertyAPI();
