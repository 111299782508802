import { all } from 'redux-saga/effects';

import listSaga from './list/saga';
import createSaga from './create/saga';
import viewSaga from './view/saga';
import editSaga from './edit/saga';
import removeSaga from './remove/saga';

function* translationSaga() {
    yield all([
        listSaga(),
        createSaga(),
        viewSaga(),
        editSaga(),
        removeSaga(),
    ])
}

export default translationSaga;