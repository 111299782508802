import {
    FETCH_USER,
    FETCH_USER_SUCCESS,
    FETCH_USER_ERROR,
    UPDATE_USER,
    CLEAN_USER,

    USER_ADD_SOCIAL_ACCOUNT,
    USER_REMOVE_SOCIAL_ACCOUNT,
} from './actionTypes';

const initialState = {
    data: null,
    loading: false,
    error: null,
};

const login = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USER:
            state = {
                ...state,
                loading: true,
                error: null,
            };
            break;

        case FETCH_USER_SUCCESS:
            state = {
                ...state,
                loading: false,
                data: action.payload,
            };
            break;

        case FETCH_USER_ERROR:
            state = {
                ...state,
                data: null,
                loading: false,
                error: action.payload,
            };
            break;

        case UPDATE_USER:
            state = {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload,
                },
            };
            break;

        case USER_ADD_SOCIAL_ACCOUNT:
            state = {
                ...state,
                data: {
                    ...state.data,
                    social_accounts: [
                        ...state.data.social_accounts,
                        action.payload
                    ],
                },
            };
            break;

        case USER_REMOVE_SOCIAL_ACCOUNT:
            state = {
                ...state,
                data: {
                    ...state.data,
                    social_accounts: state.data.social_accounts.filter((item) => item.id !== action.payload.id),
                },
            };
            break;

        case CLEAN_USER:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default login;