import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {withTranslation} from 'react-i18next';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import {cleanUsers, fetchUsers, usersResetSort, usersSetPage, usersSetSort} from 'store/actions';
import {UsersListTableView} from './components';
import {Container} from "reactstrap";
import {PageTitle} from "../../components/Custom";
import _ from "lodash";
import {getTitleByRole} from "../../helpers";


const UsersListComponent = (props) => {
  const [search, setSearch] = useState(null);

  useEffect(() => {
    let params = {
      page: props.page,
      per_page: 12,
      role: props.role
    };

    if (props.filterIsActive) {
      params.filter = props.filter;
    }


    if (props.sort.length > 0) {
      params.sort = props.sort;
    }

    if (search) {
      params.filter = {
        ...params.filter,
        keywords: search,
        search_fields: ['name', 'email', 'phone', 'company_name'],
      };
    }


    props.fetchUsers(params);

  }, [props.filterIsActive, props.filter, props.sort, props.page, search]);

  useEffect(() => {
    return () => {
      props.cleanUsers();
      props.usersResetSort();
    }
  }, []);

  const handleSearchChange = (value) => {
    props.usersSetPage(1);
    setSearch(value);
  };

  const filterWarningVisible = useMemo(() => {
    return props.search && props.list.length === 0 && !props.listLoading;
  }, [props.list, props.listLoading, props.search])

  const handleSort = useCallback(sortBy => {
    if (!_.isEqual(sortBy, props.sort)) {
      props.usersSetSort(sortBy);
    }
  }, [props.sort]);

  const handlePageChange = (item) => {
    props.usersSetPage(item.selected + 1);
  };


  return (
      <div className="page-content min-vh-100">
        <Container fluid>

          <PageTitle title={getTitleByRole(props.role)}/>

          <UsersListTableView
              title={getTitleByRole(props.role)}
              search={search}
              onSearch={handleSearchChange}
              list={props.list}
              listError={props.listError}
              listLoading={props.listLoading}
              filterWarningVisible={filterWarningVisible}
              meta={props.meta}
              onSort={handleSort}
              onPageChange={handlePageChange}
          />
        </Container>
      </div>
  );
};

const mapStateToProps = state => {
  const {filter, filterIsActive, sort, page, list, listLoading, listError, meta} = state.users.list;
  return {
    filter,
    filterIsActive,
    sort,
    page,
    list,
    listLoading,
    listError,
    meta
  };
};

const mapDispatchToProps = {
  fetchUsers,
  cleanUsers,
  usersResetSort,
  usersSetSort,
  usersSetPage,
};

export const UsersList = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UsersListComponent)));