import {
    FETCH_USERS,
    FETCH_USERS_SUCCESS,
    FETCH_USERS_ERROR,
    CLEAN_USERS,

    USERS_SET_PAGE,
    USERS_SET_SEARCH, USERS_SET_FILTER, USERS_RESET_FILTER, USERS_SET_SORT, USERS_RESET_SORT,
} from './actionTypes';

export const fetchUsers = (params) => {
    return {
        type: FETCH_USERS,
        payload: { params }
    }
};

export const fetchUsersSuccess = (data) => {
    return {
        type: FETCH_USERS_SUCCESS,
        payload: data,
    }
};

export const fetchUsersError = (data) => {
    return {
        type: FETCH_USERS_ERROR,
        payload: data,
    }
};

export const cleanUsers = () => {
    return { type: CLEAN_USERS };
};

// SET PAGE
export const usersSetPage = (data) => {
    return {
        type: USERS_SET_PAGE,
        payload: data,
    }
};

// SET SEARCH
export const usersSetSearch = (data) => {
    return {
        type: USERS_SET_SEARCH,
        payload: data,
    }
};


// SET USERS FILTER
export const usersSetFilter = (data) => {
    return {
        type: USERS_SET_FILTER,
        payload: data,
    }
};

export const usersResetFilter = () => {
    return { type: USERS_RESET_FILTER };
};

// SET USERS SORTING
export const usersSetSort = (data) => {
    return {
        type: USERS_SET_SORT,
        payload: data,
    }
};

export const usersResetSort = () => {
    return { type: USERS_RESET_SORT };
};