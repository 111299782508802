import React, {useEffect, useMemo, useState} from 'react';
import {Link, useRouteMatch} from "react-router-dom";
import {useSortBy, useTable} from "react-table";
import {PhoneFormat, TableView} from "components/Custom";
import placeholder from 'assets/images/custom/avatar-placeholder.png';
import {useTranslation} from "react-i18next";

export const UsersListTable = props => {
  const {t} = useTranslation();
  const {items} = props;
  const {path} = useRouteMatch();

  const data = useMemo(() => {
    return items;
  }, [items]);


  const [columns, setColumns] = useState([{
    Header: '',
    accessor: 'avatar',
    id: 'avatar',
    Cell: ({value}) => (
        <img src={value ? value : placeholder} alt="" className="avatar-xs rounded-circle avatar-cover"/>
    ),
    disableSortBy: true,
  }, {
    Header: t('vendor'),
    accessor: 'company',
    id: 'company',
    Cell: ({ row: { original } }) => (
        <div className={'text-dark'}>{original.company ? original.company.name : '-'}</div>
    ),
  }, {
    Header: t('name'),
    accessor: 'name',
    id: 'name',
    Cell: ({ row: { original }, value }) => {
      return <Link to={`/users/${original.id}`} className="text-dark font-weight-bold">{value}</Link>;
    },
  }, {
    Header: t('email'),
    accessor: 'email',
    id: 'email',
    Cell: ({ row: { original }, value }) => {
      return <Link to={`/users/${original.id}`} className="text-dark text-decoration-underline">{value}</Link>;
    },
  }, {
    Header: t('phone'),
    accessor: 'phone',
    id: 'phone',
    Cell: ({value}) => {
      return <PhoneFormat value={value}/>;
    },
  },
    {
      Header: "",
      // accessor: 'avatar',
      id: 'properties',
      Cell: ({row: {original}}) => (
          <Link to={`/users/${original.id}/properties`}
                className="text-dark font-weight-bold text-decoration-underline">{t("title.property.list")}</Link>
      ),
      disableSortBy: true,
    }]);


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: {sortBy}
  } = useTable(
      {
        columns,
        data,
        initialState: {
          sortBy: props.initialSort || [],
        },
        defaultCanSort: false,
        disableSortBy: data.length === 0, // data.length === 0,
        manualSortBy: true,
        disableMultiSort: true,
        autoResetSortBy: false,
      },
      useSortBy,
  );

  useEffect(() => {
    if (props.onSort) {
      props.onSort(sortBy);
    }
  }, [props.onSort, sortBy]);

  const getColumnStyle = (column) => {
    return {...column.style};
  };

  return (
      <div className="table-responsive table-centered">
        <TableView
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            rows={rows}
            prepareRow={prepareRow}
            getColumnStyle={getColumnStyle}
            columns={columns}
            isLoading={props.isLoading}
        />
      </div>
  );
};

