import {
  USER_ACCOUNT_GET_DATA,
  USER_ACCOUNT_GET_DATA_SUCCESS,
  USER_ACCOUNT_GET_DATA_ERROR,
  USER_ACCOUNT_CLEAN_STATE,
} from './actionTypes';

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const data = (state = initialState, action) => {
  switch (action.type) {
    case USER_ACCOUNT_GET_DATA:
      state = {
        ...state,
        loading: true,
        error: null,
      };
      break;

    case USER_ACCOUNT_GET_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        data: action.payload,
      };
      break;

    case USER_ACCOUNT_GET_DATA_ERROR:
      state = {
        ...state,
        data: null,
        loading: false,
        error: action.payload,
      };
      break;

    case USER_ACCOUNT_CLEAN_STATE:
      state = {
        ...state,
        ...initialState,
      };
      break;

    default:
      state = {...state};
      break;
  }
  return state;
};

export default data;