import {all} from 'redux-saga/effects';

import dataSaga from './data/saga';
import invoiceSagas from './invoice/sagas';

function* userAccountSaga() {
  yield all([
    dataSaga(),
    invoiceSagas(),
  ])
}

export default userAccountSaga;