import React, { useEffect, useMemo } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader, Alert } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";

import { useTitle } from "../../hooks";
import { BackButton, PageTitle } from '../../components/Custom';
import { TranslationForm } from './components';
import { createTranslation, cleanCreateTranslation } from '../../store/actions';

const TranslationCreateComponent = (props) => {
    useTitle(props.t('title.translation_create'));
    const history = useHistory();

    useEffect(() => {
        return () => {
            props.cleanCreateTranslation();
        };
    }, []);

    const isDisabled = useMemo(() => {
        return props.createLoading;
    }, [props.createLoading]);


    const onSubmit = (values) => {
        if (isDisabled) {
            return;
        }

        let params = {
            ...values,
        };

        props.createTranslation(params, history);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <PageTitle title={props.t('title.translation_create')} />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardHeader className="bg-transparent">
                                    <Row className="no-gutters justify-content-between">
                                        <BackButton />
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    {props.createError && props.createError.message ? <Alert color="danger">{props.createError.message}</Alert> : null}

                                    <TranslationForm
                                        onSubmit={onSubmit}
                                        isDisabled={isDisabled}
                                        isLoading={props.createLoading}
                                    />

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    const { createLoading, createError } = state.translation.create;

    return {
        createLoading,
        createError,
    };
};

const mapDispatchToProps = {
    createTranslation,
    cleanCreateTranslation,
};

export const TranslationCreate = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TranslationCreateComponent)));