import axios from 'axios';

import { paramsSerializer } from '../../utils/request';

class TranslationAPI {
    getTranslations = async (params) => {
        return axios.get('api/admin/translations', {
            params,
            paramsSerializer: () => paramsSerializer(params),
        });
    };

    createTranslation = async (params) => {
        return axios.post('api/admin/translations', params);
    };

    getTranslation = async (id) => {
        return axios.get(`api/admin/translations/${id}`);
    };

    updateTranslation = async (id, params) => {
        return axios.put(`api/admin/translations/${id}`, params);
    };

    removeTranslation = async (id) => {
        return axios.delete(`api/admin/translations/${id}`);
    };
}

export const translation = new TranslationAPI();
