import {all} from 'redux-saga/effects';

import invoiceSaga from './invoice/sagas';

function* billingSaga() {
  yield all([
    invoiceSaga(),
  ])
}

export default billingSaga;