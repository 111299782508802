import { takeLatest, fork, put, all, call } from 'redux-saga/effects';

import { FETCH_TRANSLATIONS } from './actionTypes';
import {
    fetchTranslationsSuccess,
    fetchTranslationsError,
} from './actions';

import { API } from '../../../api';
import { getMapRequestParams } from '../../../utils/request';

function* fetchTranslations({ payload: { params } }) {
    try {
        const requestParams = getMapRequestParams(params);

        const response = yield call(API.translation.getTranslations, requestParams);

        const { data } = response;
        yield put(fetchTranslationsSuccess(data));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchTranslationsError(message));
    }
}

export function* watchTranslationsFetch() {
    yield takeLatest(FETCH_TRANSLATIONS, fetchTranslations)
}

function* listSaga() {
    yield all([
        fork(watchTranslationsFetch),
    ]);
}

export default listSaga;