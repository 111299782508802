import React, {useEffect} from 'react';
import {
  useParams, withRouter,
} from "react-router-dom"

import {Routing} from "./components";
import {userAccountGetData, userAccountCleanState} from 'store/actions';
import * as HOC from 'HOC';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Container} from "reactstrap";

const DataShower = HOC.withDataShower();

const UserAccountComponent = (props) => {
  let {id} = useParams();

  useEffect(() => {
    props.userAccountGetData(id);

    return () => {
      props.userAccountCleanState();
    };
  }, []);

  return (
    <div className="page-content min-vh-100">
      <Container fluid>
        <DataShower
          isLoading={props.loading}
          isFailed={props.error}
          error={props.error}
          customLoaderClass={'mt-5'}
        >
          {props.data && (
            <Routing/>
          )}
        </DataShower>
      </Container>
    </div>
  );
};

const mapStateToProps = state => {
  const {data, loading, error} = state.userAccount.data;
  return {
    data,
    loading,
    error,
  };
};

const mapDispatchToProps = {
  userAccountGetData,
  userAccountCleanState,
};

export const UserAccount = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserAccountComponent)));

