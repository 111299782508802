import React, { useEffect, useMemo } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader, Alert } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter, useHistory, useParams } from "react-router-dom";

import { useTitle } from "../../hooks";
import { BackButton, PageTitle } from '../../components/Custom';
import { TranslationForm } from './components';
import {
    fetchTranslation,
    cleanTranslation,
    editTranslation,
    cleanEditTranslation,
    removeTranslation,
    cleanRemoveTranslation,
} from '../../store/actions';
import { MODE_EDIT } from '../../consts/common';

import * as HOC from '../../HOC';
const DataShower = HOC.withDataShower();

const TranslationEditComponent = (props) => {
    useTitle(props.t('title.translation_edit'));
    const history = useHistory();
    let { id } = useParams();

    useEffect(() => {
        props.fetchTranslation(id);

        return () => {
            props.cleanTranslation();
            props.cleanEditTranslation();
            props.cleanRemoveTranslation();
        };
    }, []);

    const isDisabled = useMemo(() => {
        return props.editLoading;
    }, [props.editLoading]);

    const onSubmit = (values) => {
        if (isDisabled) {
            return;
        }

        let params = {
            ...values,
        };

        props.editTranslation(id, params, history);
    };

    const onRemove = () => {
        props.removeTranslation(id, history);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <PageTitle title={props.t('title.translation_edit')} />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardHeader className="bg-transparent">
                                    <Row className="no-gutters justify-content-between">
                                        <BackButton />
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    {props.editError && props.editError.message ? <Alert color="danger">{props.editError.message}</Alert> : null}

                                    <DataShower
                                        isLoading={props.dataLoading}
                                        isFailed={props.dataError}
                                        error={props.dataError}
                                    >
                                        {props.data && (
                                            <TranslationForm
                                                initialValues={props.data}
                                                onSubmit={onSubmit}
                                                mode={MODE_EDIT}
                                                isDisabled={isDisabled}
                                                isLoading={props.editLoading}
                                                onRemove={onRemove}
                                                removeLoading={props.removeLoading}
                                            />
                                        )}
                                    </DataShower>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    const { data, dataLoading, dataError } = state.translation.view;
    const { editLoading, editError } = state.translation.edit;
    const { removeLoading, removeError } = state.translation.remove;

    return {
        data, dataLoading, dataError,
        editLoading, editError,
        removeLoading, removeError,
    };
};

const mapDispatchToProps = {
        fetchTranslation,
        cleanTranslation,
        editTranslation,
        cleanEditTranslation,
        removeTranslation,
        cleanRemoveTranslation,
};

export const TranslationEdit = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TranslationEditComponent)));