export const STATUS_FOR_SALE = 'for_sale';
export const STATUS_ON_HOLD = 'on_hold';
export const STATUS_SOLD = 'sold';

export const PROPERTY_TYPE_OWNED = 'owned';
export const PROPERTY_TYPE_RECEIVED = 'received';
export const PROPERTY_TYPE_SUGGESTED = 'for_ad';

export const PROPERTY_PRICE_MIN = 0;
export const PROPERTY_PRICE_MAX = 2000000;

export const PROPERTY_PRICE_RANGE_STEP = 1000;

export const PROPERTY_SHOW_STATUS_VISIBLE = '1';
export const PROPERTY_SHOW_STATUS_HIDDEN = '0';

export const PROPERTY_TYPE_HOUSE = 'house';
export const PROPERTY_TYPE_APARTMENT = 'apartment';
export const PROPERTY_TYPE_STUDIO = 'studio';
export const PROPERTY_TYPE_LOFT = 'loft';
export const PROPERTY_TYPE_OFFICE = 'office';
export const PROPERTY_TYPE_RETAIL_SHOP = 'retail_shop';
export const PROPERTY_TYPE_STORE = 'store';
export const PROPERTY_TYPE_RESTAURANT_BAR = 'restaurant_bar';
export const PROPERTY_TYPE_SERVICE_FLAT = 'service_flat';
export const PROPERTY_TYPE_DORMITORY = 'dormitory';
export const PROPERTY_TYPE_SECOND_HOME = 'second_home';
export const PROPERTY_TYPE_CONSTRUCTION_YARD = 'construction_yard';
export const PROPERTY_TYPE_GARAGE = 'garage';
export const PROPERTY_TYPE_HOTEL = 'hotel_room';

export const PROPERTY_SIZE_MIN = 0;
export const PROPERTY_SIZE_MAX = 999999;

export const PROPERTY_BEDROOMS_MIN = 0;
export const PROPERTY_BEDROOMS_MAX = 999999999;

export const PROPERTY_BATHROOMS_MIN = 0;
export const PROPERTY_BATHROOMS_MAX = 999999999;

export const PROPERTY_GARDEN_SIZE_MIN = 0;
export const PROPERTY_GARDEN_SIZE_MAX = 999999;

export const PROPERTY_TERRACE_SIZE_MIN = 0;
export const PROPERTY_TERRACE_SIZE_MAX = 999999;

export const PROPERTY_GARAGES_MIN = 0;
export const PROPERTY_GARAGES_MAX = 999999999;

export const PROPERTY_EPC_MIN = 0;
export const PROPERTY_EPC_MAX = 999999;

export const PROPERTY_EXPECTATION_PERCENT_MIN = 0;
export const PROPERTY_EXPECTATION_PERCENT_MAX = 100;

export const PROPERTY_PARKING_OUTSIDE = 'outside';
export const PROPERTY_PARKING_INSIDE = 'inside';
export const PROPERTY_PARKING_CARPORT = 'carport';

export const PROPERTY_AGE_BRAND_NEW = 'brand_new';
export const PROPERTY_AGE_RECENT = 'recent';
export const PROPERTY_AGE_TO_RENOVATE = 'to_renovate';

export const PROPERTY_OUTSIDE_FACING_NORTH = 'north';
export const PROPERTY_OUTSIDE_FACING_EAST = 'east';
export const PROPERTY_OUTSIDE_FACING_SOUTH = 'south';
export const PROPERTY_OUTSIDE_FACING_WEST = 'west';

export const PROPERTY_FLOOR_GROUND_FLOOR = 'groundfloor';
export const PROPERTY_FLOOR_MIDDLE_FLOOR = 'middlefloor';
export const PROPERTY_FLOOR_PENTHOUSE_FLOOR = 'penthouse_floor';

export const PROPERTY_INVESTMENT_GOAL_RENT = 'rent';
export const PROPERTY_INVESTMENT_GOAL_PROPERTY_VALUE = 'property_value';
export const PROPERTY_INVESTMENT_GOAL_BOTH = 'both';

export const PROPERTY_INVESTMENT_LOAN_TYPE_NONE = 'none';
export const PROPERTY_INVESTMENT_LOAN_TYPE_NORMAL_CREDIT = 'normal_credit';
export const PROPERTY_INVESTMENT_LOAN_TYPE_BULLET_CREDIT = 'bullet_credit';

export const PROPERTY_INVESTMENT_ENTITY_PERSONAL = 'personal';
export const PROPERTY_INVESTMENT_ENTITY_COMPANY = 'company';


export const PROPERTY_FILTER_INITIAL_VALUES = {
  types: null,
  companies: null,
  projects: null,
  cities: null,
  price: {
    range: [PROPERTY_PRICE_MIN, PROPERTY_PRICE_MAX],
    empty_price: false,
  },
  statuses: null,
  enabled: null,
};