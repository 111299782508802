import React, { useState } from 'react';
import { Row, Col, Button, TabContent, TabPane } from "reactstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Field, reduxForm } from 'redux-form'

import {
    required as requiredValidator,
} from "../../../../utils/validations";
import { FORM_TRANSLATION } from "../../../../consts/forms";
import TextInput from "../../../../components/Common/TextInput";
import ButtonLoadingIcon from '../../../../components/Common/ButtonLoadingIcon';
import { MODE_EDIT } from '../../../../consts/common';
import { ConfirmRemoveButtons } from "../../../../components/Custom";
import { LanguageTabsNav } from "./LanguageTabsNav";
import { crmLanguages } from '../../../../helpers';

const TranslationFormComponent = (props) => {
    const [activeLang, setActiveLang] = useState(crmLanguages[0]);

    const onTabChange = (tab) => {
        setActiveLang(tab);
    }

    return (
        <form
            onSubmit={props.handleSubmit(props.onSubmit)}
            className="form-horizontal"
            noValidate={true}
        >
            <Field
                name="key"
                label={props.t('field.label.translation.key')}
                placeholder={props.t('field.placeholder.translation.key')}
                id={'key'}
                type="text"
                component={TextInput}
                validate={[ requiredValidator ]}
                row
            />

            <div className={'mt-4 pt-4 mb-4'}>
                <LanguageTabsNav
                    active={activeLang}
                    tabs={crmLanguages}
                    onChange={onTabChange}
                />
            </div>

            <TabContent activeTab={activeLang.code}>
                {crmLanguages.map(item => (
                    <TabPane tabId={item.code} key={item.code}>
                        <Field
                            name={`value.${item.code}`}
                            label={props.t('field.label.translation')}
                            placeholder={props.t('field.placeholder.translation')}
                            id={`value.${item.code}`}
                            type="textarea"
                            component={TextInput}
                            validate={[ requiredValidator ]}
                            row
                        />
                    </TabPane>
                ))}
            </TabContent>

            <Row className="justify-content-end">
                <Col lg="10">
                    <Row className="no-gutters justify-content-between">
                        <Button
                            type="submit"
                            color="primary"
                            disabled={props.isDisabled}
                        >
                            <ButtonLoadingIcon loading={props.isLoading} />
                            {props.mode === MODE_EDIT ? props.t('save') : props.t('create')}
                        </Button>

                        {props.mode === MODE_EDIT && (
                            <ConfirmRemoveButtons
                                isLoading={props.removeLoading}
                                isDisabled={props.isLoading || props.removeLoading}
                                onConfirm={props.onRemove}
                            />
                        )}
                    </Row>
                </Col>
            </Row>
        </form>
    );
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = {};

const Translation = reduxForm({
    form: FORM_TRANSLATION,
    enableReinitialize: true,
})(TranslationFormComponent);

export const TranslationForm = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Translation)));