import React from 'react';
import {CardTitle, Card, CardBody, Table} from "reactstrap";
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import {PhoneFormat} from "components/Custom"

export const ProfileDetailsInfo = ({title, data = {}}) => {
  const {t} = useTranslation();

  const {email, name, role, company, phone, created_at, activated} = data

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">{title ? t(title) : t('personal_information')}</CardTitle>

        <div className="table-responsive">
          <Table className="table-nowrap mb-0">
            <tbody>
            <tr>
              <th scope="row">{t('email')} :</th>
              <td>{email}</td>
            </tr>
            <tr>
              <th scope="row">{t('full_name')} :</th>
              <td>{name}</td>
            </tr>
            <tr>
              <th scope="row">{t('role')} :</th>
              <td>{t(role)}</td>
            </tr>
            <tr>
              <th scope="row">{t('company')} :</th>
              <td>{company?.name}</td>
            </tr>
            <tr>
              <th scope="row">{t('phone')} :</th>
              <td>
                <PhoneFormat value={phone}/>
              </td>
            </tr>
            <tr>
              <th scope="row">{t('date.added')} :</th>
              <td>{moment(created_at).format('DD/MM/YYYY')}</td>
            </tr>
            <tr>
              <th scope="row">{t('activated')} :</th>
              <td>{activated ? t("yes") : t("no")}</td>
            </tr>
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};