import {
    LOGIN_USER,
    LOGIN_SUCCESS,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    API_ERROR,
    LOGIN_CLEAN,
    API_SUCCESS,
    SOCIAL_LOGIN_USER,
    SOCIAL_LOGIN_USER_SUCCESS,
    SOCIAL_LOGIN_USER_ERROR,
} from './actionTypes';

export const loginUser = (params, history) => {
    return {
        type: LOGIN_USER,
        payload: { params, history }
    }
};

export const loginSuccess = (data) => {
    return {
        type: LOGIN_SUCCESS,
        payload: data,
    }
};

export const loginClean = () => {
    return {
        type: LOGIN_CLEAN,
    };
};


export const logoutUser = (history) => {
    return {
        type: LOGOUT_USER,
        payload: { history },
    }
};

export const logoutUserSuccess = () => {
    return {
        type: LOGOUT_USER_SUCCESS,
        payload: {}
    }
};

export const apiError = (error) => {
    return {
        type: API_ERROR,
        payload: error,
    }
};

export const apiSuccess = (data) => {
    return {
        type: API_SUCCESS,
        payload: data,
    }
};


// social login user
export const socialLoginUser = (params) => {
    return {
        type: SOCIAL_LOGIN_USER,
        payload: { params }
    }
};

export const socialLoginUserSuccess = (data) => {
    return {
        type: SOCIAL_LOGIN_USER_SUCCESS,
        payload: data
    }
};

export const socialLoginUserError = (data) => {
    return {
        type: SOCIAL_LOGIN_USER_ERROR,
        payload: data
    }
};