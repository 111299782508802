import {
    FETCH_TRANSLATIONS,
    FETCH_TRANSLATIONS_SUCCESS,
    FETCH_TRANSLATIONS_ERROR,
    CLEAN_TRANSLATIONS,

    TRANSLATIONS_SET_PAGE,
    TRANSLATIONS_SET_SEARCH,
} from './actionTypes';

const initialState = {
    list: [],
    listLoading: false,
    listError: null,
    page: 1,
    meta: null,

    search: null,
};

const list = (state = initialState, action) => {
    switch (action.type) {

        case FETCH_TRANSLATIONS:
            state = {
                ...state,
                listLoading: true,
                listError: null,
            };
            break;

        case FETCH_TRANSLATIONS_SUCCESS:
            state = {
                ...state,
                listLoading: false,
                list: action.payload.data,
                meta: action.payload.meta,
            };
            break;

        case FETCH_TRANSLATIONS_ERROR:
            state = {
                ...state,
                listLoading: false,
                listError: action.payload,
            };
            break;

        case CLEAN_TRANSLATIONS:
            state = {
                ...state,
                ...initialState,
            };
            break;

        case TRANSLATIONS_SET_PAGE:
            state = {
                ...state,
                page: action.payload,
            };
            break;

        case TRANSLATIONS_SET_SEARCH:
            state = {
                ...state,
                search: action.payload,
                page: initialState.page,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default list;