import { all } from 'redux-saga/effects';

import listSaga from './list/saga';


function* usersSaga() {
    yield all([
        listSaga(),
    ])
}

export default usersSaga;