import {all} from 'redux-saga/effects';

import CommonSaga from './common/saga';
import AuthSaga from './auth/login/saga';
import ForgetSaga from './auth/forgetpwd/saga';
import ResetPasswordSaga from './auth/resetpwd/saga';
import LayoutSaga from './layout/saga';

import userSaga from './user/saga';
import profileSaga from './profile/saga';
import userDataSagas from './userData/sagas';
import socialSagas from './social/sagas';
import socketSagas from './socket/sagas';
import translationSagas from './translation/sagas';
import usersSagas from './users/sagas';
import propertySagas from './property/sagas';
import userAccountSagas from './userAccount/sagas';
import billingSagas from './billing/sagas';

export default function* rootSaga() {
  yield all([
    CommonSaga(),
    AuthSaga(),
    ForgetSaga(),
    ResetPasswordSaga(),
    LayoutSaga(),

    userSaga(),
    profileSaga(),
    userDataSagas(),
    socialSagas(),
    socketSagas(),
    translationSagas(),
    usersSagas(),
    propertySagas(),
    userAccountSagas(),
    billingSagas(),
  ])
}