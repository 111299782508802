import React, { useEffect } from 'react';
import { Row, Col, Alert } from "reactstrap";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Field, reduxForm } from 'redux-form'
import { useTranslation } from 'react-i18next';

// action
import { userForgetPassword, userForgetPasswordClean } from "../../store/actions";

// import images
import logo from "../../assets/images/logo-light.png";

import {
    email as emailValidator,
    required as requiredValidator,
} from "../../utils/validations";
import { FORM_FORGOT_PASSWORD } from "../../consts/forms";

import ButtonLoadingIcon from '../../components/Common/ButtonLoadingIcon';
import TextInput from '../../components/Common/TextInput';
import { useTitle } from "../../hooks";

const ForgetPasswordComponent = (props) => {
    const { t } = useTranslation();
    useTitle(t('title.forgot.password'));

    useEffect(() => {
        return () => {
            props.userForgetPasswordClean();
        }
        // eslint-disable-next-line
    }, []);

    const { handleSubmit } = props;

    const onSubmit = (values) => {
        props.userForgetPassword(values, props.history);
    };

    return (
        <div className="d-flex flex-column h-100">
            <div className="mb-4 mb-md-5">
                <Link to="/" className="auth-logo">
                    <img
                        src={logo}
                        alt=""
                        height="18"
                    />
                    <span className="auth-logo-brand text-dark"> Brikk</span>
                </Link>
            </div>
            <div className="my-auto">
                <div>
                    <h5 className="text-primary">{t('forgot.password')}</h5>
                    <p></p>
                </div>

                <div className="mt-4">
                    {props.forgetError && props.forgetError ? (
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                            {props.forgetError}
                        </Alert>
                    ) : null}
                    {props.forgetSuccessMsg ? (
                        <Alert color="success" style={{ marginTop: "13px" }}>
                            {props.forgetSuccessMsg}
                        </Alert>
                    ) : null}

                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="form-horizontal mt-4"
                        noValidate={true}
                    >
                        <Field
                            name="email"
                            label={t('field.label.email')}
                            placeholder={t('field.placeholder.email')}
                            id={'email'}
                            type="email"
                            component={TextInput}
                            validate={[ requiredValidator, emailValidator ]}
                        />

                        <Row className="form-group">
                            <Col className="text-right">
                                <button
                                    className="btn btn-primary w-md waves-effect waves-light"
                                    type="submit"
                                    disabled={props.loading}
                                >
                                    <ButtonLoadingIcon loading={props.loading} />
                                    {t('reset')}
                                </button>
                            </Col>
                        </Row>
                    </form>

                    <div className="mt-5 text-center">
                        <p>
                            {t('remember_it')}{" "}
                            <Link
                                to="/login"
                                className="font-weight-medium text-primary"
                            >
                                {" "}
                                {t('login')}
                            </Link>{" "}
                        </p>
                    </div>
                </div>
            </div>

            <div className="mt-4 mt-md-5 text-center">
                <p className="mb-0">
                    © {new Date().getFullYear()} Brikk.
                </p>
            </div>
        </div>
    );
};

const mapStatetoProps = state => {
    const { forgetError, forgetSuccessMsg, loading } = state.ForgetPassword;
    return { forgetError, forgetSuccessMsg, loading };
};

const ForgetPasswordPage = reduxForm({
    form: FORM_FORGOT_PASSWORD,
})(ForgetPasswordComponent);

export default withRouter(
    connect(mapStatetoProps, { userForgetPassword, userForgetPasswordClean })(ForgetPasswordPage)
);
