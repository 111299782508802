import axios from 'axios';
import {paramsSerializer} from "utils/request";

class UsersAPI {
  getUsers = async (params) => {
    return axios.get(`api/admin/users`, {
      params,
      paramsSerializer: () => paramsSerializer(params),
    });
  };

  getUser = async (id, params) => {
    return axios.get(`api/admin/users/${id}`, {
      params,
      paramsSerializer: () => paramsSerializer(params),
    });
  };

  // Billing invoices
  getUserInvoices = async (id, params) => {
    return axios.get(`api/admin/users/${id}/invoices`, {
      params,
      paramsSerializer: () => paramsSerializer(params),
    });
  }
}

export const users = new UsersAPI();
