import {
    EDIT_PROFILE,
    EDIT_PROFILE_SUCCESS,
    EDIT_PROFILE_ERROR,
    UPDATE_AVATAR,
    UPDATE_AVATAR_SUCCESS,
    UPDATE_AVATAR_ERROR,
    REMOVE_AVATAR,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_ERROR,
    REMOVE_USER,
    REMOVE_USER_SUCCESS,
    REMOVE_USER_ERROR,
    CLEAN_PROFILE_PAGE_INFO,
} from './actionTypes';

const initialState = {
    editProfileLoading: false,
    editProfileSuccess: null,
    editProfileError: null,

    avatarLoading: false,
    avatarSuccess: null,
    avatarError: null,

    passwordLoading: false,
    passwordSuccess: null,
    passwordError: null,

    removeLoading: false,
    removeSuccess: null,
    removeError: null,

    updateCompanyLogoLoading: false,
    updateCompanyLogoLoadingSuccess: null,
    updateCompanyLogoLoadingError: null,
};

const profile = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_PROFILE:
            state = {
                ...state,
                editProfileLoading: true,
                editProfileSuccess: null,
                editProfileError: null,
            };
            break;

        case EDIT_PROFILE_SUCCESS:
            state = {
                ...state,
                editProfileLoading: false,
                editProfileSuccess: action.payload,
                editProfileError: null,
            };
            break;

        case EDIT_PROFILE_ERROR:
            state = {
                ...state,
                editProfileLoading: false,
                editProfileSuccess: null,
                editProfileError: action.payload,
            };
            break;

        case UPDATE_AVATAR:
            state = {
                ...state,
                avatarLoading: true,
                avatarSuccess: null,
                avatarError: null,
            };
            break;

        case UPDATE_AVATAR_SUCCESS:
            state = {
                ...state,
                avatarLoading: false,
                avatarSuccess: action.payload,
                avatarError: null,
            };
            break;

        case UPDATE_AVATAR_ERROR:
            state = {
                ...state,
                avatarLoading: false,
                avatarSuccess: null,
                avatarError: action.payload,
            };
            break;

        case REMOVE_AVATAR:
            state = {
                ...state,
                avatarLoading: true,
                avatarSuccess: null,
                avatarError: null,
            };
            break;

        case CHANGE_PASSWORD:
            state = {
                ...state,
                passwordLoading: true,
                passwordSuccess: null,
                passwordError: null,
            };
            break;

        case CHANGE_PASSWORD_SUCCESS:
            state = {
                ...state,
                passwordLoading: false,
                passwordSuccess: action.payload,
                passwordError: null,
            };
            break;

        case CHANGE_PASSWORD_ERROR:
            state = {
                ...state,
                passwordLoading: false,
                passwordSuccess: null,
                passwordError: action.payload,
            };
            break;

        case REMOVE_USER:
            state = {
                ...state,
                removeLoading: true,
                removeSuccess: null,
                removeError: null,
            };
            break;

        case REMOVE_USER_SUCCESS:
            state = {
                ...state,
                removeLoading: false,
                removeSuccess: action.payload,
                removeError: null,
            };
            break;

        case REMOVE_USER_ERROR:
            state = {
                ...state,
                removeLoading: false,
                removeSuccess: null,
                removeError: action.payload,
            };
            break;

        case CLEAN_PROFILE_PAGE_INFO:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default profile;