import {
    LOGIN_USER,
    LOGIN_SUCCESS,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    API_ERROR,
    LOGIN_CLEAN,
    API_SUCCESS,

    SOCIAL_LOGIN_USER,
    SOCIAL_LOGIN_USER_SUCCESS,
    SOCIAL_LOGIN_USER_ERROR,
} from './actionTypes';

const initialState = {
    serverError: null,
    success: null,
    loading: false,

    isLoggedIn: false,
    isOnboarding: false,

    socialLoading: false,
    socialSuccess: null,
    socialError: null,
};

const login = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_USER:
            state = {
                ...state,
                loading: true,
                serverError: null,
                success: null,
            };
            break;

        case LOGIN_SUCCESS:
            state = {
                ...state,
                loading: false,
                isLoggedIn: true,
            };
            break;

        case LOGIN_CLEAN:
            state = {
                ...state,
                serverError: null,
                success: null,
                loading: false,

                socialLoading: false,
                socialSuccess: null,
                socialError: null,
            };
            break;

        case LOGOUT_USER:
            state = { ...state };
            break;

        case LOGOUT_USER_SUCCESS:
            state = {
                ...state,
                isLoggedIn: false,
                isOnboarding: false,
            };
            break;

        case API_ERROR:
            state = {
                ...state,
                serverError: action.payload,
                loading: false,
            };
            break;

        case API_SUCCESS:
            state = {
                ...state,
                loading: false,
                success: action.payload,
            };
            break;

        // social login
        case SOCIAL_LOGIN_USER:
            state = {
                ...state,
                socialLoading: true,
                socialError: null,
                socialSuccess: null,
                serverError: null,
            };
            break;

        case SOCIAL_LOGIN_USER_SUCCESS:
            state = {
                ...state,
                socialLoading: false,
                serverError: null,
                socialSuccess: action.payload,
            };
            break;

        case SOCIAL_LOGIN_USER_ERROR:
            state = {
                ...state,
                socialLoading: false,
                socialError: action.payload,
                socialSuccess: null,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default login;