import React, { useEffect } from 'react';
import { Container } from "reactstrap";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { PageTitle } from '../../components/Custom';
import { useTitle } from "../../hooks";

const DashboardComponent = (props) => {
    useTitle(props.t('title.dashboard'));

    useEffect(() => {
        return () => {
        };
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <PageTitle title={props.t('dashboard')} />

                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    return {
        user: state.user.data,
    };
};

const mapDispatchToProps = {
};

export const Dashboard = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardComponent));