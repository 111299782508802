import {combineReducers} from "redux";

import listReducer from './list/reducer';
import importReducer from './import/reducer';
import removeReducer from './remove/reducer';

const propertyReducer = combineReducers({
  list: listReducer,
  import: importReducer,
  remove: removeReducer
});

export default propertyReducer;