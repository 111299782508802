import {
    EDIT_TRANSLATION,
    EDIT_TRANSLATION_SUCCESS,
    EDIT_TRANSLATION_ERROR,
    CLEAN_EDIT_TRANSLATION,
} from './actionTypes';

const initialState = {
    editLoading: false,
    editSuccess: null,
    editError: null,
};

const edit = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_TRANSLATION:
            state = {
                ...state,
                editLoading: true,
                editSuccess: null,
                editError: null,
            };
            break;

        case EDIT_TRANSLATION_SUCCESS:
            state = {
                ...state,
                editLoading: false,
                editSuccess: action.payload,
            };
            break;

        case EDIT_TRANSLATION_ERROR:
            state = {
                ...state,
                editLoading: false,
                editError: action.payload,
            };
            break;

        case CLEAN_EDIT_TRANSLATION:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default edit;