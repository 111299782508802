import {
    FETCH_PROPERTY_LIST,
    FETCH_PROPERTY_LIST_SUCCESS,
    FETCH_PROPERTY_LIST_ERROR,
    CLEAN_PROPERTY_LIST_PAGE,
    PROPERTY_SET_FILTER,
    PROPERTY_RESET_FILTER,
    PROPERTY_SET_SORT,
    PROPERTY_RESET_SORT,
    PROPERTY_SET_PAGE,
    PROPERTY_LIST_SEND_FAVORITE_STATUS,
    PROPERTY_LIST_UPDATE_ITEM, TRIGGER_FETCH_PROPERTY_LIST,
} from './actionTypes';

export const fetchPropertyList = (id, params) => {
    return {
        type: FETCH_PROPERTY_LIST,
        payload: { id, params }
    }
};

export const fetchPropertyListSuccess = (data) => {
    return {
        type: FETCH_PROPERTY_LIST_SUCCESS,
        payload: data,
    }
};

export const fetchPropertyListError = (data) => {
    return {
        type: FETCH_PROPERTY_LIST_ERROR,
        payload: data,
    }
};

export const cleanPropertyListPage = () => {
    return { type: CLEAN_PROPERTY_LIST_PAGE };
};


// SET PROPERTY FILTER
export const propertySetFilter = (data) => {
    return {
        type: PROPERTY_SET_FILTER,
        payload: data,
    }
};

export const propertyResetFilter = () => {
    return { type: PROPERTY_RESET_FILTER };
};

// SET PROPERTY SORTING
export const propertySetSort = (data) => {
    return {
        type: PROPERTY_SET_SORT,
        payload: data,
    }
};

export const propertyResetSort = () => {
    return { type: PROPERTY_RESET_SORT };
};


// SET PROPERTY PAGE
export const propertySetPage = (data) => {
    return {
        type: PROPERTY_SET_PAGE,
        payload: data,
    }
};

// PROPERTY SEND FAVORITE STATUS
export const propertyListSendFavoriteStatus = (id, params) => {
    return {
        type: PROPERTY_LIST_SEND_FAVORITE_STATUS,
        payload: { id, params },
    }
};

// PROPERTY LIST UPDATE ITEM IN LIST
export const propertyListUpdateItem = (id, data) => {
    return {
        type: PROPERTY_LIST_UPDATE_ITEM,
        payload: { id, data },
    }
};


export const triggerFetchPropertyList = (page) => {
    return { type: TRIGGER_FETCH_PROPERTY_LIST, payload: {page} };
};