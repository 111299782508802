import {
  USER_ACCOUNT_GET_DATA,
  USER_ACCOUNT_GET_DATA_SUCCESS,
  USER_ACCOUNT_GET_DATA_ERROR,
  USER_ACCOUNT_CLEAN_STATE,
} from './actionTypes';

export const userAccountGetData = (id) => {
  return {
    type: USER_ACCOUNT_GET_DATA,
    payload: { id },
  }
};

export const userAccountGetDataSuccess = (data) => {
  return {
    type: USER_ACCOUNT_GET_DATA_SUCCESS,
    payload: data,
  }
};

export const userAccountGetDataError = (data) => {
  return {
    type: USER_ACCOUNT_GET_DATA_ERROR,
    payload: data,
  }
};

export const userAccountCleanState = () => {
  return {type: USER_ACCOUNT_CLEAN_STATE};
};