import React, { useEffect } from 'react';
import { FormGroup, Alert, Button, Row } from "reactstrap";
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form'
import { useTranslation } from 'react-i18next';

// actions
import { loginUser, apiError, loginClean, logoutUser } from '../../store/actions';

// import images
import logo from "../../assets/images/logo-light.png";

import { FORM_SIGN_IN } from '../../consts/forms';

import ButtonLoadingIcon from '../../components/Common/ButtonLoadingIcon';
import TextInput from '../../components/Common/TextInput';
import { loginValidation } from './validations/validate';
import { useTitle } from '../../hooks';
import { ValidationErrors } from '../../components/Custom';

const LoginComponent = (props) => {
    const { t } = useTranslation();
    useTitle(t('title.login'));

    const { handleSubmit } = props;

    useEffect(() => {
        return () => {
            props.loginClean();
        }
        // eslint-disable-next-line
    }, []);

    const onSubmit = (values) => {
        props.loginUser(values, props.history);
    };

    // temp decision: remove after backend fixed
    const logOut = async () => {
        props.history.push('/')
        props.logoutUser(props.history);
    };

    return (
        <div className="d-flex flex-column h-100">
            <div className="mb-4 mb-md-5">
                <Link to="/" className="auth-logo">
                    <img
                        src={logo}
                        alt=""
                        height="18"
                    />
                    <span className="auth-logo-brand text-dark"> Brikk</span>
                </Link>
            </div>
            <div className="my-auto">
                <div>
                    <h5 className="text-primary">{t('login')}</h5>
                </div>

                <div className="mt-4">
                    {props.serverError && props.serverError ? (
                        <>
                            <Alert color="danger">{props.serverError.message}</Alert>

                            {props.serverError.needLogout && (
                                <Row className={'justify-content-end'}>
                                    <Button color="light" outline className="waves-effect mb-2" onClick={logOut}>
                                        <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"/>
                                        {t('logout')}
                                    </Button>
                                </Row>
                            )}
                        </>
                    ) : null}

                    {props.success && props.success ? <Alert color="success">{props.success}</Alert> : null}

                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="form-horizontal"
                        noValidate={true}
                    >
                        <Field
                            name="email"
                            label={t('field.label.email')}
                            placeholder={t('field.placeholder.email')}
                            id={'email'}
                            type="email"
                            component={TextInput}
                        />

                        <FormGroup>
                            {/*<div className="float-right">*/}
                            {/*    <Link*/}
                            {/*        to="/forgot-password"*/}
                            {/*        className="text-muted"*/}
                            {/*    >*/}
                            {/*        {t('forgot.your.password')}*/}
                            {/*    </Link>*/}
                            {/*</div>*/}

                            <Field
                                name="password"
                                label={t('field.label.password')}
                                placeholder={t('field.placeholder.password')}
                                id={'password'}
                                type="password"
                                component={TextInput}
                                translateParams={{ count: 8 }}
                            />
                        </FormGroup>

                        <div className="mt-3">
                            <button
                                className="btn btn-primary btn-block waves-effect waves-light"
                                type="submit"
                                disabled={props.loading}
                            >
                                <ButtonLoadingIcon loading={props.loading} />
                                {t('button.login')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <div className="mt-4 mt-md-5 text-center">
                <p className="mb-0">
                    © {new Date().getFullYear()} Brikk.
                </p>
            </div>
        </div>
    );
};

const mapStatetoProps = state => {
    const {
        serverError, loading, success,
    } = state.Login;
    return {
        serverError, loading, success,
    };
};

const Login = reduxForm({
    form: FORM_SIGN_IN,
    validate: loginValidation,
})(LoginComponent);

export default withRouter(connect(mapStatetoProps, { loginUser, apiError, loginClean, logoutUser })(Login));

