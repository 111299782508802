import {
    SET_LANGUAGE,
    SET_APP_IS_VISIBLE,
} from './actionTypes';

export const setLanguage = (data) => ({
    type: SET_LANGUAGE,
    payload: data
});

export const setAppIsVisible = (data) => ({
    type: SET_APP_IS_VISIBLE,
    payload: data
});