import {
  USER_ACCOUNT_INVOICE_LIST_GET_DATA,
  USER_ACCOUNT_INVOICE_LIST_GET_DATA_SUCCESS,
  USER_ACCOUNT_INVOICE_LIST_GET_DATA_ERROR,
  USER_ACCOUNT_INVOICE_LIST_CLEAN_STATE,
  USER_ACCOUNT_INVOICE_LIST_SET_PAGE,
} from './actionTypes';

export const userAccountInvoiceListGetData = (id, params) => {
  return {
    type: USER_ACCOUNT_INVOICE_LIST_GET_DATA,
    payload: {id, params}
  }
};

export const userAccountInvoiceListGetDataSuccess = (data) => {
  return {
    type: USER_ACCOUNT_INVOICE_LIST_GET_DATA_SUCCESS,
    payload: data,
  }
};

export const userAccountInvoiceListGetDataError = (data) => {
  return {
    type: USER_ACCOUNT_INVOICE_LIST_GET_DATA_ERROR,
    payload: data,
  }
};

export const userAccountInvoiceListCleanState = () => {
  return {type: USER_ACCOUNT_INVOICE_LIST_CLEAN_STATE};
};

export const userAccountInvoiceListSetPage = (data) => {
  return {
    type: USER_ACCOUNT_INVOICE_LIST_SET_PAGE,
    payload: data,
  }
};