import {
    REMOVE_TRANSLATION,
    REMOVE_TRANSLATION_SUCCESS,
    REMOVE_TRANSLATION_ERROR,
    CLEAN_REMOVE_TRANSLATION,
} from './actionTypes';

export const removeTranslation = (id, history) => {
    return {
        type: REMOVE_TRANSLATION,
        payload: { id, history },
    }
};

export const removeTranslationSuccess = (data) => {
    return {
        type: REMOVE_TRANSLATION_SUCCESS,
        payload: data,
    }
};

export const removeTranslationError = (data) => {
    return {
        type: REMOVE_TRANSLATION_ERROR,
        payload: data,
    }
};

export const cleanRemoveTranslation = () => {
    return { type: CLEAN_REMOVE_TRANSLATION };
};