import React from 'react';
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
} from "react-router-dom"

import AllowedRoute from 'routes/middleware/AllowedRoute';
import { InvoiceDetails } from "../../Invoices";

export const Routing = () => {
  let {path} = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={"/"}/>
      </Route>
      <AllowedRoute path={`${path}/invoices/:id`} component={InvoiceDetails} allowed={true}/>

      <Route>
        <Redirect to={"/"}/>
      </Route>
    </Switch>
  );
};

