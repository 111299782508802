import { combineReducers } from "redux";

import listReducer from './list/reducer';
import createReducer from './create/reducer';
import viewReducer from './view/reducer';
import editReducer from './edit/reducer';
import removeReducer from './remove/reducer';

const translationReducer = combineReducers({
    list: listReducer,
    create: createReducer,
    view: viewReducer,
    edit: editReducer,
    remove: removeReducer,
});

export default translationReducer;