import {
  BILLING_INVOICE_DATA_GET_DATA,
  BILLING_INVOICE_DATA_GET_DATA_SUCCESS,
  BILLING_INVOICE_DATA_GET_DATA_ERROR,
  BILLING_INVOICE_DATA_CLEAN_STATE,
} from './actionTypes';

const initialState = {
  invoice: null,
  invoiceLoading: false,
  invoiceError: null,
};

const data = (state = initialState, action) => {
  switch (action.type) {
    case BILLING_INVOICE_DATA_GET_DATA:
      state = {
        ...state,
        invoiceLoading: true,
        invoiceError: null,
      };
      break;

    case BILLING_INVOICE_DATA_GET_DATA_SUCCESS:
      state = {
        ...state,
        invoiceLoading: false,
        invoice: action.payload,
      };
      break;

    case BILLING_INVOICE_DATA_GET_DATA_ERROR:
      state = {
        ...state,
        invoiceLoading: false,
        invoiceError: action.payload,
      };
      break;

    case BILLING_INVOICE_DATA_CLEAN_STATE:
      state = {
        ...state,
        ...initialState,
      };
      break;

    default:
      state = {...state};
      break;
  }
  return state;
};

export default data;