import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';

import i18n from '../../i18n';
import { FETCH_USER } from './actionTypes';
import { fetchUserSuccess, fetchUserError } from './actions';
import { setLanguage } from '../common/actions';
import { logoutUser } from '../auth/login/actions';
import { ROLE_SUPER_ADMIN } from '../../consts/roles';

import { API } from '../../api';

function* fetchUser({ payload: { history } }) {
    try {
        const includes = [];

        const response = yield call(API.user.getMe, { includes });

        const { data } = response;

        const { lang, role } = data.data;

        if (role !== ROLE_SUPER_ADMIN) {
            yield put(logoutUser(history));
            return;
        }

        if (i18n.language !== lang) {
            i18n.changeLanguage(lang);

            yield put(setLanguage(lang));
            API.setLang(lang);
        }

        yield put(fetchUserSuccess(data?.data));

    } catch (error) {
        yield put(fetchUserError('Server Error'));
    }
}

export function* watchUserFetch() {
    yield takeEvery(FETCH_USER, fetchUser)
}

function* userSaga() {
    yield all([
        fork(watchUserFetch),
    ]);
}

export default userSaga;