import {
    EDIT_PROFILE,
    EDIT_PROFILE_SUCCESS,
    EDIT_PROFILE_ERROR,
    UPDATE_AVATAR,
    UPDATE_AVATAR_SUCCESS,
    UPDATE_AVATAR_ERROR,
    REMOVE_AVATAR,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_ERROR,
    REMOVE_USER,
    REMOVE_USER_SUCCESS,
    REMOVE_USER_ERROR,
    CLEAN_PROFILE_PAGE_INFO,
} from './actionTypes';

// EDIT USER

export const editProfile = (params) => {
    return {
        type: EDIT_PROFILE,
        payload: { params }
    }
};

export const editProfileSuccess = (data) => {
    return {
        type: EDIT_PROFILE_SUCCESS,
        payload: data,
    }
};

export const editProfileError = (data) => {
    return {
        type: EDIT_PROFILE_ERROR,
        payload: data,
    }
};

// UPDATE AVATAR

export const updateAvatar = (params) => {
    return {
        type: UPDATE_AVATAR,
        payload: { params }
    }
};

export const updateAvatarSuccess = (data) => {
    return {
        type: UPDATE_AVATAR_SUCCESS,
        payload: data,
    }
};

export const updateAvatarError = (data) => {
    return {
        type: UPDATE_AVATAR_ERROR,
        payload: data,
    }
};

export const removeAvatar = () => {
    return {
        type: REMOVE_AVATAR,
        payload: {}
    }
};

// CHANGE PASSWORD

export const changePassword = (params) => {
    return {
        type: CHANGE_PASSWORD,
        payload: { params }
    }
};

export const changePasswordSuccess = (data) => {
    return {
        type: CHANGE_PASSWORD_SUCCESS,
        payload: data,
    }
};

export const changePasswordError = (data) => {
    return {
        type: CHANGE_PASSWORD_ERROR,
        payload: data,
    }
};

// REMOVE USER

export const removeUser = (history) => {
    return {
        type: REMOVE_USER,
        payload: { history }
    }
};

export const removeUserSuccess = (data) => {
    return {
        type: REMOVE_USER_SUCCESS,
        payload: data,
    }
};

export const removeUserError = (data) => {
    return {
        type: REMOVE_USER_ERROR,
        payload: data,
    }
};

export const cleanProfilePageInfo = () => {
    return { type: CLEAN_PROFILE_PAGE_INFO };
};