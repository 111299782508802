import {REMOVE_PROPERTY, REMOVE_PROPERTY_COMPLETED} from './actionTypes';

const initialState = {
    loading: false,
};


const remove = (state = initialState, action) => {
    switch (action.type) {

        // PROPERTY LIST
        case REMOVE_PROPERTY:
            state = {
                ...state,
                loading: true,
            };
            break;
        case REMOVE_PROPERTY_COMPLETED:
            state = {
                ...state,
                loading: false,
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default remove;