import { PHONE_CODE } from '../consts/common';

const phone = value => {
    if (!value) {
        return value
    }

    let prefix = '+';
    let result = value;

    if (value.length >= 2 && value.slice(0, 2) === PHONE_CODE) {
        result = prefix + value;
    }

    return result;
};

export {
    phone
};