import {all} from 'redux-saga/effects';

import dataSaga from './data/saga';

function* invoiceSaga() {
  yield all([
    dataSaga(),
  ])
}

export default invoiceSaga;