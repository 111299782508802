import {
  BILLING_INVOICE_DATA_GET_DATA,
  BILLING_INVOICE_DATA_GET_DATA_SUCCESS,
  BILLING_INVOICE_DATA_GET_DATA_ERROR,
  BILLING_INVOICE_DATA_CLEAN_STATE,
} from './actionTypes';

export const billingInvoiceDataGetData = (id) => {
  return {
    type: BILLING_INVOICE_DATA_GET_DATA,
    payload: {id},
  }
};

export const billingInvoiceDataGetDataSuccess = (data) => {
  return {
    type: BILLING_INVOICE_DATA_GET_DATA_SUCCESS,
    payload: data,
  }
};

export const billingInvoiceDataGetDataError = (data) => {
  return {
    type: BILLING_INVOICE_DATA_GET_DATA_ERROR,
    payload: data,
  }
};

export const billingInvoiceDataCleanState = () => {
  return {type: BILLING_INVOICE_DATA_CLEAN_STATE};
};