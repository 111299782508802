import React from 'react';
import {Redirect, Route, Switch, useRouteMatch} from 'react-router-dom';
import AllowedRoute from '../../routes/middleware/AllowedRoute';
import {USER_ROLE_CLIENT, USER_ROLE_MANAGER} from "../../consts/roles";
import {UsersList} from "./UsersList";
import {UserAccount} from "../UserAccount";

export const UsersIndex = () => {
  let {path} = useRouteMatch();

  return (
    <Switch>

      <Route exact path={'/users'}>
        <Redirect to={'/'}/>
      </Route>
      <AllowedRoute exact path={'/clients'} component={UsersList} allowed={true} role={USER_ROLE_CLIENT}/>
      <AllowedRoute exact path={'/managers'} component={UsersList} allowed={true} role={USER_ROLE_MANAGER}/>
      <AllowedRoute path={`${path}/:id`} component={UserAccount} allowed={true}/>

      <Route>
        <Redirect to={path}/>
      </Route>
    </Switch>
  );
};

