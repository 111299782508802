import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './locales/en/translation.json';

import { SUPPORTED_LANGUAGE_CODES } from './consts/locales';

// the translations
const resources = {
    en: {
        translation: translationEN,
    },
};

const DEFAULT_LANGUAGE_CODE = process.env.REACT_APP_DEFAULT_LANGUAGE_CODE;

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: {
            order: ['localStorage'],
        },
        resources,
        // lng: "en",
        fallbackLng: DEFAULT_LANGUAGE_CODE,
        supportedLngs: SUPPORTED_LANGUAGE_CODES,

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        },

        debug: false,

        // react-i18next options
        react: {
            wait: true
        },
    });

export default i18n;