import {
    REMOVE_TRANSLATION,
    REMOVE_TRANSLATION_SUCCESS,
    REMOVE_TRANSLATION_ERROR,
    CLEAN_REMOVE_TRANSLATION,
} from './actionTypes';

const initialState = {
    removeLoading: false,
    removeSuccess: null,
    removeError: null,
};

const remove = (state = initialState, action) => {
    switch (action.type) {
        case REMOVE_TRANSLATION:
            state = {
                ...state,
                removeLoading: true,
                removeSuccess: null,
                removeError: null,
            };
            break;

        case REMOVE_TRANSLATION_SUCCESS:
            state = {
                ...state,
                removeLoading: false,
                removeSuccess: action.payload,
            };
            break;

        case REMOVE_TRANSLATION_ERROR:
            state = {
                ...state,
                removeLoading: false,
                removeError: action.payload,
            };
            break;

        case CLEAN_REMOVE_TRANSLATION:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default remove;