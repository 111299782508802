import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import {FETCH_PROPERTY_LIST} from './actionTypes';
import {fetchPropertyList, fetchPropertyListError, fetchPropertyListSuccess,} from './actions';

import {API} from '../../../api';
import {getMapRequestParams} from '../../../utils/request';

function* getList({payload: {id, params}}) {
  try {
    const includes = ['project', 'city', 'myReceivedLink', 'myReceivedLink.sender', 'myReceivedLink.sender.company']; // 'images', 'link.receiver', 'mySentLinks'

    let requestParams = {
      includes,
      filter: {
        links_statuses: ['active'],
        leads_links_statuses: ['active'],
      }
    };

    requestParams = getMapRequestParams(params, requestParams);

    const response = yield call(API.property.getUserPropertyList, id, requestParams);

    const {data} = response;
    const {
      meta: {current_page, last_page},
    } = data
    if (current_page && last_page && current_page > last_page) {
      yield put(
          fetchPropertyList(
              id,
              {
                ...params,
                page: last_page,
              },
          )
      )
    } else {
      yield put(fetchPropertyListSuccess(data))
    }
    yield put(fetchPropertyListSuccess(data));

  } catch (error) {
    const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
    yield put(fetchPropertyListError(message));
  }
}

export function* watchPropertyListFetch() {
  yield takeLatest(FETCH_PROPERTY_LIST, getList)
}


function* listSaga() {
  yield all([
    fork(watchPropertyListFetch),
  ]);
}

export default listSaga;