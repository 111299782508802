import React, { useMemo } from 'react';
import { Row, Button } from "reactstrap";
import { useTranslation } from 'react-i18next';

import { SocialAuth } from '../SocialAuth';
import { SOCIAL_FACEBOOK_PROVIDER, SOCIAL_GOOGLE_PROVIDER } from "../../../../consts/social";

const SocialAccountsLinkComponent = (props) => {
    const { t } = useTranslation();

    const config = [{
        provider: SOCIAL_GOOGLE_PROVIDER,
        icon: 'mdi mdi-google',
        color: 'bg-danger border-danger',
        data: null,
    }, {
        provider: SOCIAL_FACEBOOK_PROVIDER,
        icon: 'mdi mdi-facebook',
        color: 'bg-primary border-primary',
        data: null,
    }];

    const renderButtons = () => {
        return buttons.map(item => (
            <Button
                onClick={() => handleClick(item)} key={item.provider}
                disabled={item.disabled}
                color="light" outline className="waves-effect social-linking-button"
            >
                <div className={'d-flex flex-column align-items-center'}>
                    <span className={`social-list-item mb-1 ${item.color}`}>
                        <i className={`font-size-16 align-middle text-white ${item.icon}`}/>
                    </span>
                    {item.data ? (
                        <div className={'d-flex flex-column align-items-center'}>
                            {item.data?.name && (
                                <div className={'social-link-name'}>
                                    {item.data?.name}
                                </div>
                            )}

                            <span className={'text-primary'}>
                                {t('social.remove_linking')}
                            </span>
                        </div>
                    ) : (
                        <span className={'text-primary'}>
                            {t('social.link_account')}
                        </span>
                    )}
                </div>
            </Button>
        ));
    };

    const linkingData = (provider) => props.accounts.find((item) => item.provider === provider);

    const isDisabled = (provider) => {
        return props.pending.find((item) => item === provider);
    };

    const buttons = useMemo(() => {
        return config.map(item => {
            return {
                ...item,
                data: linkingData(item.provider),
                disabled: Boolean(isDisabled(item.provider)),
            }
        })
    }, [props.accounts, props.pending]);

    const handleClick = (item) => {
        if (item.data) {
            props.onRemove(item.data);
        } else {
            props.onLogin(item.provider);
        }
    };

    return (
        <Row className={'no-gutters'}>
            <div className="button-items">

                {renderButtons()}

            </div>
        </Row>
    );
};

export const SocialAccountsLink = (props) => (
    <SocialAuth {...props} component={SocialAccountsLinkComponent} />
);