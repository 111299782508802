import React from 'react';
import {Row, Col} from "reactstrap";
import {withTranslation} from 'react-i18next';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

import {useTitle} from "hooks";
import {BackButton, PageTitle} from 'components/Custom';
import {InvoicesContainer} from './components';

const InvoicesComponent = (props) => {
  useTitle(props.t('title.user_invoices'));

  return (
    <>
      <PageTitle title={props.t('invoices')}/>

      <Row className={'mb-4'}>
        <Col>
          <Row className={'no-gutters justify-content-between'}>
            <BackButton/>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col>
          <InvoicesContainer/>
        </Col>
      </Row>

    </>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = {};

export const Invoices = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InvoicesComponent)));