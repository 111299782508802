import {
    EDIT_TRANSLATION,
    EDIT_TRANSLATION_SUCCESS,
    EDIT_TRANSLATION_ERROR,
    CLEAN_EDIT_TRANSLATION,
} from './actionTypes';

export const editTranslation = (id, params, history) => {
    return {
        type: EDIT_TRANSLATION,
        payload: { id, params, history },
    }
};

export const editTranslationSuccess = (data) => {
    return {
        type: EDIT_TRANSLATION_SUCCESS,
        payload: data,
    }
};

export const editTranslationError = (data) => {
    return {
        type: EDIT_TRANSLATION_ERROR,
        payload: data,
    }
};

export const cleanEditTranslation = () => {
    return { type: CLEAN_EDIT_TRANSLATION };
};