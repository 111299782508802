import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { FETCH_TRANSLATION } from './actionTypes';
import {
    fetchTranslationSuccess,
    fetchTranslationError,
} from './actions';

import { API } from '../../../api';

function* fetchTranslation({ payload: { id } }) {
    try {
        const response = yield call(API.translation.getTranslation, id);

        const { data } = response;
        yield put(fetchTranslationSuccess(data?.data));

    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';
        yield put(fetchTranslationError(message));
    }
}

export function* watchTranslationFetch() {
    yield takeEvery(FETCH_TRANSLATION, fetchTranslation)
}

function* viewSaga() {
    yield all([
        fork(watchTranslationFetch),
    ]);
}

export default viewSaga;