import {
    FETCH_USER,
    FETCH_USER_SUCCESS,
    FETCH_USER_ERROR,
    UPDATE_USER,
    CLEAN_USER,
    USER_ADD_SOCIAL_ACCOUNT,
    USER_REMOVE_SOCIAL_ACCOUNT,
} from './actionTypes';

export const fetchUser = (history) => {
    return {
        type: FETCH_USER,
        payload: { history },
    }
};

export const fetchUserSuccess = (data) => {
    return {
        type: FETCH_USER_SUCCESS,
        payload: data,
    }
};

export const fetchUserError = (data) => {
    return {
        type: FETCH_USER_ERROR,
        payload: data,
    }
};

export const updateUser = (data) => {
    return {
        type: UPDATE_USER,
        payload: data,
    }
};

export const userAddSocialAccount = (data) => {
    return {
        type: USER_ADD_SOCIAL_ACCOUNT,
        payload: data,
    }
};

export const userRemoveSocialAccount = (data) => {
    return {
        type: USER_REMOVE_SOCIAL_ACCOUNT,
        payload: data,
    }
};

export const cleanUser = () => {
    return { type: CLEAN_USER };
};