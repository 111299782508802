import React, { useState } from 'react';
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";

import ButtonLoadingIcon from "../../Common/ButtonLoadingIcon";

export const ConfirmRemoveButtons = (props) => {
    const { isLoading, isDisabled, onConfirm } = props;

    const { t } = useTranslation();

    const [visible, setVisible] = useState(false);

    const handleRemove = () => {
        toggleVisible();
    };

    const handleConfirm = () => {
        toggleVisible();
        onConfirm();
    };

    const toggleVisible = () => {
        setVisible(!visible);
    }

    return (
        <>
            {!visible ? (
                <Button
                    type="button"
                    color="danger"
                    disabled={isDisabled || isLoading}
                    onClick={handleRemove}
                >
                    <ButtonLoadingIcon loading={isLoading} />
                    {t('remove')}
                </Button>
            ) : (
                <div className="button-items">
                    <Button
                        type="button"
                        color="light"
                        outline
                        onClick={toggleVisible}
                    >
                        {t('cancel')}
                    </Button>

                    <Button
                        type="button"
                        color="primary"
                        disabled={isDisabled || isLoading}
                        onClick={handleConfirm}
                    >
                        {t('confirm')}
                    </Button>
                </div>
            )}
        </>
    );
};