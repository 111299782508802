import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { stopSubmit, reset } from 'redux-form';

import i18n from '../../i18n';
import { EDIT_PROFILE, CHANGE_PASSWORD, UPDATE_AVATAR, REMOVE_AVATAR, REMOVE_USER } from './actionTypes';
import {
    editProfileSuccess,
    editProfileError,
    changePasswordSuccess,
    changePasswordError,
    updateAvatarSuccess,
    updateAvatarError,
    removeUserSuccess,
    removeUserError,
} from './actions';
import { updateUser } from '../user/actions';
import { logoutUser } from '../auth/login/actions';
import { setLanguage } from '../common/actions';

import { FORM_EDIT_PROFILE, FORM_CHANGE_PASSWORD } from '../../consts/forms';

import { API } from '../../api';

function* editProfile({ payload: { params } }) {
    try {
        const response = yield call(API.user.editProfile, params);

        const { data } = response;
        yield put(editProfileSuccess('profile.edit.success'));

        yield put(updateUser(data.data));

        const { lang } = data.data;

        if (i18n.language !== lang) {
            i18n.changeLanguage(lang);

            yield put(setLanguage(lang));
            API.setLang(lang);
        }

    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(editProfileError({
            errors,
            message
        }));

        yield put(stopSubmit(FORM_EDIT_PROFILE, errors));
    }
}

function* changePassword({ payload: { params } }) {
    try {
        const response = yield call(API.user.changePassword, params);

        const { data } = response;
        yield put(changePasswordSuccess('password.change.success'));
        yield put(reset(FORM_CHANGE_PASSWORD));

    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(changePasswordError({
            errors,
            message
        }));

        yield put(stopSubmit(FORM_CHANGE_PASSWORD, errors));
    }
}

function* updateAvatar({ payload: { params } }) {
    try {
        const response = yield call(API.user.updateAvatar, params);

        const { data } = response;

        yield put(updateUser({ avatar: data.data.src }));

        yield put(updateAvatarSuccess(true));

    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(updateAvatarError({
            errors,
            message
        }));
    }
}

function* removeAvatar() {
    try {
        const response = yield call(API.user.removeAvatar);

        const { data } = response;

        yield put(updateUser({ avatar: null }));

        yield put(updateAvatarSuccess(true));

    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(updateAvatarError({
            errors,
            message
        }));
    }
}

function* removeUser({ payload: { history } }) {
    try {
        const response = yield call(API.user.removeUser);

        const { data } = response;
        yield put(removeUserSuccess(true));
        yield put(logoutUser(history));

    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(removeUserError({
            errors,
            message
        }));
    }
}

export function* watchEditProfile() {
    yield takeEvery(EDIT_PROFILE, editProfile);
}

export function* watchChangePassword() {
    yield takeEvery(CHANGE_PASSWORD, changePassword);
}

export function* watchUpdateAvatar() {
    yield takeEvery(UPDATE_AVATAR, updateAvatar);
}

export function* watchRemoveAvatar() {
    yield takeEvery(REMOVE_AVATAR, removeAvatar);
}

export function* watchRemoveUser() {
    yield takeEvery(REMOVE_USER, removeUser);
}

function* profileSaga() {
    yield all([
        fork(watchEditProfile),
        fork(watchChangePassword),
        fork(watchUpdateAvatar),
        fork(watchRemoveAvatar),
        fork(watchRemoveUser),
    ]);
}

export default profileSaga;