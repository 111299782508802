import {combineReducers} from "redux";
import {reducer as formReducer} from 'redux-form'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import Layout from "./layout/reducer";
import commonReducer from './common/reducer';

import Login from "./auth/login/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import ResetPassword from "./auth/resetpwd/reducer";

import userReducer from './user/reducer';

import profileReducer from './profile/reducer';
import userDataReducers from './userData/reducers';
import socialReducers from './social/reducers';
import socketReducers from './socket/reducers';
import translationReducers from './translation/reducers';
import usersReducers from './users/reducers';
import propertyReducers from './property/reducers';
import userAccountReducers from './userAccount/reducers';
import billingReducers from './billing/reducers';

const authPersistConfig = {
  key: 'Login',
  storage,
  whitelist: ['isLoggedIn'],
};

const rootReducer = combineReducers({
  form: formReducer,

  // public
  Layout,
  common: commonReducer,
  Login: persistReducer(authPersistConfig, Login),
  ForgetPassword,
  ResetPassword,
  user: userReducer,
  profile: profileReducer,
  userData: userDataReducers,
  social: socialReducers,
  socket: socketReducers,
  translation: translationReducers,
  users: usersReducers,
  property: propertyReducers,
  userAccount: userAccountReducers,
  billing: billingReducers,
});

export default rootReducer;
