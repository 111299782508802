import {
    STATUS_FOR_SALE,
    STATUS_ON_HOLD,
    STATUS_SOLD,

    PROPERTY_TYPE_OWNED,
    PROPERTY_TYPE_RECEIVED,
    PROPERTY_TYPE_SUGGESTED,

    PROPERTY_SHOW_STATUS_VISIBLE,
    PROPERTY_SHOW_STATUS_HIDDEN,
} from '../../consts/property';
import { INVITE_STATUS_ACTIVE } from "../../consts/invite";

export const getPropertyStatusOptions = (t) => {
    return [{
        label: t('for_sale'),
        value: STATUS_FOR_SALE,
    }, {
        label: t('on_hold'),
        value: STATUS_ON_HOLD,
    }, {
        label: t('sold'),
        value: STATUS_SOLD,
    }];
};

export const getPropertyStatusConfig = (status, t) => {
    let data = {
        label: '',
        color: '',
        value: '',
    };
    switch (status) {
        case STATUS_FOR_SALE:
            data = {
                label: t('for_sale'),
                value: STATUS_FOR_SALE,
                color: 'success',
            };
            break;

        case STATUS_ON_HOLD:
            data = {
                label: t('on_hold'),
                value: STATUS_ON_HOLD,
                color: 'primary',
            };
            break;

        case STATUS_SOLD:
            data = {
                label: t('sold'),
                value: STATUS_SOLD,
                color: 'danger',
            };
            break;

        default:
            data = { ...data };
            break;
    }
    return data;
};

export const getPropertyOwnerTypeConfig = (status, t) => {
    let data = {
        label: '',
        value: '',
    };
    switch (status) {
        case PROPERTY_TYPE_OWNED:
            data = {
                label: t('property.type.owner'),
                value: PROPERTY_TYPE_OWNED,
            };
            break;

        case PROPERTY_TYPE_RECEIVED:
            data = {
                label: t('property.type.linked'),
                value: PROPERTY_TYPE_RECEIVED,
            };
            break;

        case PROPERTY_TYPE_SUGGESTED:
            data = {
                label: t('property.type.suggested'),
                value: PROPERTY_TYPE_SUGGESTED,
            };
            break;

        default:
            data = { ...data };
            break;
    }
    return data;
};

export const getPropertyOwnerTypeOptions = (t) => {
    return [{
        label: t('property.type.owner'),
        value: PROPERTY_TYPE_OWNED,
    }, {
        label: t('property.type.linked'),
        value: PROPERTY_TYPE_RECEIVED,
    }, {
        label: t('property.type.suggested'),
        value: PROPERTY_TYPE_SUGGESTED,
    }];
};

export const getPropertyReceivedTypeConfig = (status, t) => {
    return getPropertyOwnerTypeConfig(status, t);
};

export const getPropertyReceivedTypeOptions = (t) => {
    return [{
        label: t('property.type.linked'),
        value: PROPERTY_TYPE_RECEIVED,
    }, {
        label: t('property.type.suggested'),
        value: PROPERTY_TYPE_SUGGESTED,
    }];
};

export const getPropertyVisibilityOptions = (t) => {
    return [{
        label: t('visible'),
        value: PROPERTY_SHOW_STATUS_VISIBLE,
    }, {
        label: t('hidden'),
        value: PROPERTY_SHOW_STATUS_HIDDEN,
    }];
};

export const getPropertyVisibilityConfig = (status, t) => {
    let data = {
        label: '',
        value: '',
    };
    switch (status) {
        case PROPERTY_SHOW_STATUS_VISIBLE:
            data = {
                label: t('visible'),
                value: PROPERTY_SHOW_STATUS_VISIBLE,
            };
            break;

        case PROPERTY_SHOW_STATUS_HIDDEN:
            data = {
                label: t('hidden'),
                value: PROPERTY_SHOW_STATUS_HIDDEN,
            };
            break;

        default:
            data = { ...data };
            break;
    }
    return data;
};

export const isPropertyNewStatus = (property) => {
    if (property && property.myReceivedLink) {
        return property?.myReceivedLink?.status === INVITE_STATUS_ACTIVE && !property.myReceivedLink.seen;
    }
    return false;
};