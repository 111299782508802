import React, { useEffect } from 'react';
import { Container } from "reactstrap";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { PageTitle } from '../../components/Custom';
import { useTitle } from "../../hooks";
import {
    ChangePassword,
} from './components';
import { cleanProfilePageInfo } from '../../store/actions';

const ProfileComponent = (props) => {
    useTitle(props.t('title.profile'));

    useEffect(() => {
        return () => {
            props.cleanProfilePageInfo()
        };
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    {/*<PageTitle title={props.t('profile')} />*/}

                    <h4 className="card-title mb-4">{props.t('password')}</h4>
                    <ChangePassword />

                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    return {
        user: state.user.data,
    };
};

const mapDispatchToProps = {
    cleanProfilePageInfo,
};

export const Profile = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProfileComponent));