import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ResetPassword from "../pages/Authentication/ResetPassword";

// Pages
import {
    Dashboard,
    Profile,
    AuthSocial,
    TranslationIndex,
    UsersIndex,
    Billing,
} from '../pages';


const publicRoutes = [
    { path: "/login", component: Login },
    // { path: "/forgot-password", component: ForgetPwd },
    // { path: "/reset-password/:token", component: ResetPassword },
];

const utilityRoutes = [
    { path: "/auth/social", component: AuthSocial },
];

const superAdminRoutes = [
    // { path: "/dashboard", component: Dashboard },
    { path: "/profile", component: Profile },
    { path: "/translations", component: TranslationIndex },
    { path: "/clients", component: UsersIndex },
    { path: "/managers", component: UsersIndex },
    { path: "/users", component: UsersIndex },
    { path: "/billing", component: Billing },
    { path: "*", exact: true, component: () => <Redirect to="/translations" /> }
];

export { publicRoutes, utilityRoutes, superAdminRoutes };
