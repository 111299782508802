import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';
import i18n from '../../../i18n';

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN_USER } from './actionTypes';
import { loginSuccess, logoutUserSuccess, apiError, apiSuccess, socialLoginUserSuccess, socialLoginUserError } from './actions';
import { cleanUser, updateUser } from '../../user/actions';

import { FORM_SIGN_IN } from '../../../consts/forms';

import { API } from '../../../api'

import { isUserCompleteRegister } from '../../../utils/user';

function* loginUser({ payload: { params, history } }) {
    try {
        const response = yield call(API.auth.signIn, params);

        const { data } = response;
        yield put(loginSuccess(data));

        history.push('/translations');
    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(apiError({
            errors,
            message,
            needLogout: error.response?.status === 403, // temp decision: remove after backend fixed
        }));

        yield put(stopSubmit(FORM_SIGN_IN, errors));
    }
}

function* logoutUser({ payload: { history } }) {
    try {
        const response = yield call(API.auth.logOut);
        const { data } = response;
        yield put(logoutUserSuccess(data));

        yield put(cleanUser());

        history.push('/login');
    } catch (error) {
        yield put(logoutUserSuccess(''));

        yield put(cleanUser());

        history.push('/login');
    }
}

function* socialLoginUser({ payload: { params } }) {
    try {
        const response = yield call(API.auth.socialLogin, params);

        const { data } = response;

        yield put(socialLoginUserSuccess(data));

        // if user not complete register -> save user data and navigate to complete page
        if (!isUserCompleteRegister(data?.data)) {
            yield put(updateUser(data?.data));
        }

        yield put(loginSuccess(data));
    } catch (error) {
        const errors = error.response.data.errors;
        const message = error.response.data.message ? error.response.data.message : 'Server Error';
        yield put(socialLoginUserError({
            errors,
            message,
        }));
    }
}

export function* watchUserLogin() {
    yield takeEvery(LOGIN_USER, loginUser)
}

export function* watchUserLogout() {
    yield takeEvery(LOGOUT_USER, logoutUser)
}

export function* watchSocialLogin() {
    yield takeEvery(SOCIAL_LOGIN_USER, socialLoginUser)
}

function* authSaga() {
    yield all([
        fork(watchUserLogin),
        fork(watchUserLogout),
        fork(watchSocialLogin),
    ]);
}

export default authSaga;