import {all} from 'redux-saga/effects';

import listSaga from './list/saga';
import importSaga from './import/saga';
import removeSaga from './remove/saga';

function* propertySaga() {
  yield all([
    listSaga(),
    importSaga(),
    removeSaga()
  ])
}

export default propertySaga;