import axios from 'axios';
import qs from "qs";

const paramsSerializer = (params) => {
    return qs.stringify(params, { encode: false });
};

class CommonAPI {
    getCities = async (params) => {
        return axios.get('api/cities', {
            params,
            paramsSerializer: () => paramsSerializer(params),
        });
    };

    getStatic = async (params) => {
        return axios.get(`api/static/${params}`);
    };

    getRegions = async (params) => {
        return axios.get('api/regions', { params });
    };

    getCitiesByRegion = async (id, params) => {
        return axios.get(`api/regions/${id}/cities`, {
            params,
            paramsSerializer: () => paramsSerializer(params),
        });
    };
}

export const common = new CommonAPI();
