import React from 'react';
import {Row, Col} from "reactstrap";
import {withTranslation} from 'react-i18next';
import {connect} from "react-redux";
import {withRouter, Link} from "react-router-dom";

import {useTitle, useAllowed} from "hooks";
import {BackButton, PageTitle, ProfileMainInfo, ProfileDetailsInfo} from 'components/Custom';
import {USER_ROLE_MANAGER} from 'consts/roles';

const GeneralComponent = (props) => {
  useTitle(props.t('title.user_details'));

  let isManager = useAllowed([USER_ROLE_MANAGER], props.data.role);

  return (
    <>
      <PageTitle title={props.t('details')}/>

      <Row className={'mb-4'}>
        <Col>
          <Row className={'no-gutters justify-content-between'}>
            <BackButton/>

            <div className="button-items">
              {isManager && (
                <Link to={`invoices`} className="btn btn-outline-primary">
                  {props.t('invoices')}
                </Link>
              )}

              <Link to={`properties`} className="btn btn-outline-primary">
                {props.t('properties')}
              </Link>
            </div>
          </Row>
        </Col>
      </Row>

      {props.data && (
        <>
          <div className={'profile-info-container'}>
            <Row>
              <Col xl="4">
                <ProfileMainInfo
                    title={'details'}
                    name={props.data.name}
                    avatar={props.data.avatar}
                />

                <ProfileDetailsInfo data={props.data} />
              </Col>
            </Row>
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = state => {
  const {data} = state.userAccount.data;
  return {
    data,
  };
};

const mapDispatchToProps = {};

export const General = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GeneralComponent)));