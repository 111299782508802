import {takeEvery, fork, put, all, call} from 'redux-saga/effects';

import {BILLING_INVOICE_DATA_GET_DATA} from './actionTypes';
import {
  billingInvoiceDataGetDataSuccess,
  billingInvoiceDataGetDataError,
} from './actions';
import {API} from 'api';

function* getData({payload: {id}}) {
  try {
    const response = yield call(API.billing.getInvoice, id);

    const {data} = response;
    yield put(billingInvoiceDataGetDataSuccess(data?.data));

  } catch (error) {
    const message = error.response?.data?.message ? error.response.data.message : 'Error';
    yield put(billingInvoiceDataGetDataError(message));
  }
}

export function* watchGetData() {
  yield takeEvery(BILLING_INVOICE_DATA_GET_DATA, getData)
}

function* dataSaga() {
  yield all([
    fork(watchGetData),
  ]);
}

export default dataSaga;