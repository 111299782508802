import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import toastr from 'toastr';
import i18n from '../../../i18n';

import { REMOVE_TRANSLATION } from './actionTypes';
import {
    removeTranslationSuccess,
    removeTranslationError,
} from './actions';

import { API } from '../../../api';

function* removeTranslation({ payload: { id, history } }) {
    try {
        const response = yield call(API.translation.removeTranslation, id);

        const { data } = response;

        yield put(removeTranslationSuccess(true));
        history.push('/translations');

        toastr.success(i18n.t('translation.remove.success'));
    } catch (error) {
        const message = error.response?.data?.message ? error.response.data.message : 'Server Error';

        yield put(removeTranslationError({
            message
        }));

        toastr.error(message);
    }
}

export function* watchRemoveTranslation() {
    yield takeEvery(REMOVE_TRANSLATION, removeTranslation)
}

function* removeSaga() {
    yield all([
        fork(watchRemoveTranslation),
    ]);
}

export default removeSaga;