export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const API_ERROR = 'LOGIN_API_ERROR';
export const API_SUCCESS = 'LOGIN_API_SUCCESS';

export const LOGIN_CLEAN = 'LOGIN_CLEAN';

export const SOCIAL_LOGIN_USER = 'SOCIAL_LOGIN_USER';
export const SOCIAL_LOGIN_USER_SUCCESS = 'SOCIAL_LOGIN_USER_SUCCESS';
export const SOCIAL_LOGIN_USER_ERROR = 'SOCIAL_LOGIN_USER_ERROR';