export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR';
export const CLEAN_USERS = 'CLEAN_USERS';

export const USERS_SET_PAGE = 'USERS_SET_PAGE';

export const USERS_SET_SEARCH = 'USERS_SET_SEARCH';

export const USERS_SET_FILTER = 'USERS_SET_FILTER';
export const USERS_RESET_FILTER = 'USERS_RESET_FILTER';
export const USERS_SET_SORT = 'USERS_SET_SORT';
export const USERS_RESET_SORT = 'USERS_RESET_SORT';