import {takeEvery, fork, put, all, call} from 'redux-saga/effects';

import {USER_ACCOUNT_GET_DATA} from './actionTypes';
import {userAccountGetDataSuccess, userAccountGetDataError} from './actions';
import {API} from 'api';

function* getData({payload: {id}}) {
  try {
    const includes = ['company'];

    const response = yield call(API.users.getUser, id, {includes});

    const {data} = response;
    yield put(userAccountGetDataSuccess(data?.data));

  } catch (error) {
    const message = error.response?.data?.message ? error.response.data.message : 'Error';
    yield put(userAccountGetDataError(message));
  }
}

export function* watchGetData() {
  yield takeEvery(USER_ACCOUNT_GET_DATA, getData)
}

function* dataSaga() {
  yield all([
    fork(watchGetData),
  ]);
}

export default dataSaga;