import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from 'react-redux';

import { middlewareHelper } from '../../helpers/middleware';

const ProtectedRouteComponent = ({
    component: Component,
    path,
    middlewares = [],
    ...rest
}) => {

    const withMiddleware = (middleware) => {
        return middlewares.includes(middleware);
    }

    return (
        <Route {...rest}>
            <Component {...rest} />
        </Route>
    );
};

const mapStateToProps = state => {
    const { data } = state.user;

    return {
        user: data,
    };
};

export const ProtectedRoute = withRouter(connect(mapStateToProps, {})(ProtectedRouteComponent));